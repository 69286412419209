import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, MenuStateReturn } from 'reakit/Menu';
import clsx from 'clsx';
import { ReactComponent as IconOpenNewWindow } from '../../assets/icons/open-new-window.svg';
import { FCC } from '../../utils/types';
import { ExternalLinkTarget } from '../lettuce/components/ExternalLink';
import { Tag } from './Tag';
import styles from './MenuItem.module.css';

type NavigationMenuItemBodyProps = {
  label: string;
  tags?: string[];
  badge?: string;
  icon?: React.ReactNode;
  hasExternalLinkIcon?: boolean;
};

const NavigationMenuItemBody: React.FC<NavigationMenuItemBodyProps> = ({
  label,
  tags,
  badge,
  icon,
  hasExternalLinkIcon,
}) => {
  return (
    <>
      <div className={styles.menuItemBody}>
        <div className={styles.iconAndTextContainer}>
          {icon}
          {label}
          {badge ? <Tag className={styles.badge}>{badge}</Tag> : null}
        </div>
        {hasExternalLinkIcon ? <IconOpenNewWindow /> : null}
      </div>
      {tags && (
        <span className={styles.tagsContainer}>
          {tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </span>
      )}
    </>
  );
};

type BasicNavigationMenuItemProps = NavigationMenuItemBodyProps & {
  menu: MenuStateReturn;
  id?: string;
  disabled?: boolean;
  onClick?: () => void;
  as?: typeof Link | 'a' | 'button';
  to?: string;
  href?: string;
  target?: string;
  ['aria-label']?: string;
  className?: string;
};

export const BasicNavigationMenuItem: FCC<BasicNavigationMenuItemProps> = ({
  menu,
  label,
  tags,
  badge,
  icon,
  disabled = false,
  as = 'button',
  hasExternalLinkIcon = false,
  'aria-label': ariaLabel,
  className,
  ...rest
}) => {
  return (
    <MenuItem
      {...menu}
      className={clsx(styles.navigationMenuItem, className)}
      as={as}
      disabled={disabled}
      aria-label={ariaLabel ?? (typeof label === 'string' ? label : undefined)}
      {...rest}
    >
      <NavigationMenuItemBody
        label={label}
        tags={tags}
        badge={badge}
        icon={icon}
        hasExternalLinkIcon={hasExternalLinkIcon}
      />
    </MenuItem>
  );
};

type NavigationMenuItemProps = Omit<BasicNavigationMenuItemProps, 'as' | 'href'> & {
  to: string;
};

/**
 * Wraps Reakit MenuItem, for use inside a Reakit Menu component whose items are Links
 */
const NavigationMenuItem: React.FC<NavigationMenuItemProps> = props => {
  return <BasicNavigationMenuItem {...props} as={Link} />;
};

export default NavigationMenuItem;

type ExternalNavigationMenuItemProps = Omit<NavigationMenuItemProps, 'to'> & {
  href: string;
};

/** Same as a NavigationMenuItem, but renders an anchor tag with href, for external links */
export const ExternalNavigationMenuItem: React.FC<ExternalNavigationMenuItemProps> = props => {
  return (
    <BasicNavigationMenuItem
      {...props}
      as="a"
      target={ExternalLinkTarget.newTab}
      hasExternalLinkIcon={true}
    />
  );
};
