import React from 'react';
import { Link } from 'react-router-dom';
import { Account, useGetAccountQuery } from '../../generated/graphql';
import { path } from '../../components/lettuce/crisp/routing/lib';
import style from './AccountDetails.module.css';

export const useAccountDetails = (accountId: string) => {
  const { data } = useGetAccountQuery({ variables: { accountId } });
  return data?.account;
};

export const RenderAccountDetails: React.FC<Pick<Account, 'name'>> = ({ name }) => {
  return (
    <div className={style.accountDetails}>
      <div className={style.preamble}>Account</div>
      <div className={style.accountName}>{name}</div>
      <div>
        <Link className={style.link} to={path('Home')()}>
          Switch account
        </Link>
      </div>
    </div>
  );
};

type AccountDetailsProps = {
  accountId: string;
};

const AccountDetails: React.FC<AccountDetailsProps> = ({ accountId }) => {
  const account = useAccountDetails(accountId);
  if (!account) {
    return null;
  }

  return <RenderAccountDetails {...account} />;
};

export default AccountDetails;
