import React from 'react';
import { Key } from '../../../utils/keyCodes';

export type KeyHandlerEventProps = Pick<
  React.KeyboardEvent,
  'key' | 'stopPropagation' | 'preventDefault' | 'shiftKey' | 'altKey'
>;

export type OnKeyDownHandler = (event: KeyHandlerEventProps) => boolean;

export type GetListKeyHandlerParams = {
  onSelect: (index: number) => void;
  optionCount: number;
  focusedIndex?: number;
  onFocusedIndexChange: (index: number) => void;
};

export type GetListKeyHandler = (params: GetListKeyHandlerParams) => OnKeyDownHandler;

const getListKeyHandler: GetListKeyHandler =
  ({ onSelect, optionCount, focusedIndex = 0, onFocusedIndexChange }) =>
  (e: KeyHandlerEventProps) => {
    if (e.key === Key.UpArrow) {
      if (focusedIndex > 0) {
        onFocusedIndexChange(focusedIndex - 1);
      }
      e.preventDefault();
    } else if (e.key === Key.DownArrow) {
      if (focusedIndex < optionCount - 1) {
        onFocusedIndexChange(focusedIndex + 1);
      }
      e.preventDefault();
    } else if (e.key === Key.Home) {
      onFocusedIndexChange(0);
      e.preventDefault();
    } else if (e.key === Key.End) {
      onFocusedIndexChange(optionCount - 1);
      e.preventDefault();
    } else if (e.key === Key.Enter || e.key === Key.Space) {
      if (optionCount) {
        onSelect(focusedIndex);
      }
      // Prevent submit or 'Save' response when component is on a form
      e.stopPropagation();
    } else {
      return false;
    }
    return true;
  };

export default getListKeyHandler;
