import React, { useEffect } from 'react';
import { Menu, MenuButton, MenuStateReturn, useMenuState } from 'reakit/Menu';
import { Separator } from 'reakit/Separator';
import firebase from 'firebase/compat/app';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { useGetAccountBusinessDashboardsEnabledQuery } from '../../generated/graphql';
import { ReactComponent as IconMenu } from '../../assets/icons/navigation-menu.svg';
import { ReactComponent as IconHome } from '../../assets/icons/house-4.svg';
import { ReactComponent as IconDashboards } from '../../assets/icons/icon_24x24_businessdashboard.svg';
import { ReactComponent as IconInbound } from '../../assets/icons/Earth-Download.svg';
import { ReactComponent as IconOutbound } from '../../assets/icons/app-window-upload-1.svg';
import { ReactComponent as IconRemaConnect } from '../../assets/icons/rema_24x24.svg';
import { ReactComponent as IconNotifications } from '../../assets/icons/alarm-bell.svg';
import { ReactComponent as IconUsers } from '../../assets/icons/multiple-neutral-1.svg';
import { ReactComponent as IconMDM } from '../../assets/icons/lettuce/database-edit.svg';
import { ReactComponent as IconDataModels } from '../../assets/icons/data-model-table.svg';
import { ReactComponent as IconLogOut } from '../../assets/icons/logout-1.svg';
import NavigationMenuItem, {
  BasicNavigationMenuItem,
} from '../../components/ui/NavigationMenuItem';
import { Avatar, useResponsive } from '../../components/ui';
import { useAuthenticationContext } from '../../components/security/AuthenticationContext';
import {
  useAuthorizationContext,
  ViewerAuthorityGrant,
} from '../../components/security/AuthorizationContext';
import useIsFeatureFlagEnabled from '../../components/lettuce/common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../components/lettuce/common/featureFlags/KnownFlags';
import { logOut } from '../../components/security/lib';
import { Feature, trackFeature } from '../../utils/features';
import TestIds from '../../TestIds';
import useGetMdmPath from '../../components/Home/useGetMdmPath';
import { useParsedQuery, withQueryParams } from '../../utils/querystringUtils';
import useEventCallback from '../../components/lettuce/utils/useEventCallback';
import { path } from '../../components/lettuce/crisp/routing/lib';
import { ConnectorTab } from '../../components/lettuce/crisp/routing/Tabs';
import InvisibleMenuBackdrop from './InvisibleMenuBackdrop';
import useIsRemaAccountAdmin from './useIsRemaAccountAdmin';
import styles from './ComboNavMenu.module.css';

const ICON_HEIGHT = 24;
const ICON_SMALL_HEIGHT = 22;

const handleLogout = () => {
  logOut().then(() => (window.location.href = '/'));
};

const useOpenMenuOnUrlParam = (menu: MenuStateReturn) => {
  const { replace, location } = useHistory();
  const params = useParsedQuery<Record<string, string> & { show?: string }>();
  const updateUrl = useEventCallback(() => {
    replace(withQueryParams(_.omit(params, 'show'))(location.pathname));
  });
  // Exclude root pathname
  const shouldOpen = location.pathname !== '/' && params.show === 'userMenu';
  useEffect(() => {
    if (shouldOpen) {
      updateUrl();
      menu.show();
    }
  }, [shouldOpen, menu, updateUrl]);
};

type ComboNavMenuProps = {
  accountId?: string;
};

const UserDetails: React.FC<{ user: firebase.User }> = ({ user }) => {
  return (
    <div className={styles.userDetails}>
      <div className={styles.userName}>{user.displayName}</div>
      <div className={styles.userEmail}>{user.email}</div>
    </div>
  );
};

const ComboNavMenu: React.FC<ComboNavMenuProps> = ({ accountId }) => {
  const menu = useMenuState({
    baseId: 'nav-menu',
    gutter: 6,
  });
  const { firebaseUser } = useAuthenticationContext();
  const { isMaxXSmallScreen } = useResponsive();

  const {
    getHasGlobalAdminPermission,
    getHasAccountAdminPermission,
    getHasAccountReadPermission,
    getHasAccountPermission,
  } = useAuthorizationContext();

  const isRemaAccountAdmin = useIsRemaAccountAdmin();

  const isGlobalAdmin = getHasGlobalAdminPermission();
  const isAccountAdmin = accountId ? getHasAccountAdminPermission(accountId) : false;
  const isAccountViewer = accountId ? getHasAccountReadPermission(accountId) : false;

  const { data: businessDashboardsEnabledData } = useGetAccountBusinessDashboardsEnabledQuery({
    variables: { accountId: accountId ?? '' },
    skip: isGlobalAdmin || !isAccountViewer,
  });

  const businessDashboardsEnabled =
    isGlobalAdmin || !!businessDashboardsEnabledData?.account.businessDashboardsEnabled;
  const notificationsEnabled = isAccountViewer;
  const remaConnectEnabled = useIsFeatureFlagEnabled(KnownFlags.RemaConnect);

  const { mdmPath } = useGetMdmPath({ accountId });

  const onClickFeature = (featureIds: Feature[]) => () => {
    menu.hide();
    for (const f of featureIds) {
      trackFeature(f);
    }
  };

  const hasModelDocumentationPermission =
    accountId && getHasAccountPermission(accountId, ViewerAuthorityGrant);
  useOpenMenuOnUrlParam(menu);

  if (!firebaseUser) return null;

  const menuButtonProps = {
    ...menu,
    className: clsx(isMaxXSmallScreen ? styles.smallMenuButton : styles.menuButton, {
      [styles.visible]: menu.visible,
    }),
    // Required by e2e tests
    'data-testid': TestIds.BUTTON_USER_MENU,
    'data-user-email': firebaseUser.email,
  };

  return (
    <>
      <MenuButton {...menuButtonProps}>
        <IconMenu fill="#393939" height={20} width={20} />
        {!isMaxXSmallScreen && (
          <Avatar name={firebaseUser.displayName} src={firebaseUser.photoURL} size={30} />
        )}
      </MenuButton>
      <Menu
        {...menu}
        className={styles.navMenu}
        hideOnClickOutside={false}
        aria-label="Navigation menu"
      >
        <UserDetails user={firebaseUser} />
        <Separator className={styles.navMenuSeparator} />

        {accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('AccountHome')({
              accountId,
            })}
            label="Home"
            icon={<IconHome height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuHomepage])}
          />
        )}
        {businessDashboardsEnabled && accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('BusinessDashboardGroup')({
              accountId,
            })}
            label="Dashboards"
            icon={<IconDashboards height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuDashboards])}
          />
        )}
        {isAccountAdmin && accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('Connectors')({
              accountId,
              tab: ConnectorTab.Inbound,
            })}
            label="Source Connectors"
            icon={<IconInbound height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuConnectors, Feature.NavMenuInboundConnectors])}
          />
        )}
        {isAccountAdmin && accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('Connectors')({
              accountId,
              tab: ConnectorTab.Outbound,
            })}
            label="Destination Connectors"
            icon={<IconOutbound height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuConnectors, Feature.NavMenuOutboundConnectors])}
          />
        )}
        {accountId && hasModelDocumentationPermission && (
          <NavigationMenuItem
            to={path('AccountDataCatalog')({ accountId })}
            label={'Data Catalog'}
            menu={menu}
            icon={
              <IconDataModels
                height={ICON_SMALL_HEIGHT}
                style={{ marginRight: ICON_HEIGHT - ICON_SMALL_HEIGHT }}
              />
            }
            onClick={onClickFeature([Feature.NavMenuModelDocumentation])}
          />
        )}
        {remaConnectEnabled && accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('RemaConnect')({ accountId })}
            label="REMA Connect"
            icon={<IconRemaConnect height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuRemaConnect])}
          />
        )}
        {isRemaAccountAdmin && (
          <NavigationMenuItem
            menu={menu}
            to={path('RemaConnectAdminDiagnostics')()}
            label="REMA Connect Admin"
            icon={<IconUsers height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuRemaConnectAdmin])}
          />
        )}
        {notificationsEnabled && accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('Notifications')({
              accountId,
            })}
            label="Notifications"
            icon={<IconNotifications height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuNotifications])}
          />
        )}
        {isAccountAdmin && accountId && (
          <NavigationMenuItem
            menu={menu}
            to={path('ManageAccountMembers')({
              accountId,
            })}
            label="Manage Users"
            icon={<IconUsers height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuManageUsers])}
          />
        )}
        {isAccountAdmin && mdmPath && (
          <NavigationMenuItem
            menu={menu}
            to={mdmPath}
            label="Edit Products"
            icon={<IconMDM height={ICON_HEIGHT} />}
            onClick={onClickFeature([Feature.NavMenuMDM])}
          />
        )}
        <Separator className={styles.navMenuSeparator} />
        <BasicNavigationMenuItem
          menu={menu}
          label="Log out"
          icon={
            <IconLogOut
              height={ICON_SMALL_HEIGHT}
              style={{ marginRight: ICON_HEIGHT - ICON_SMALL_HEIGHT }}
            />
          }
          onClick={handleLogout}
        />
      </Menu>

      <InvisibleMenuBackdrop menuIsVisible={menu.visible} onMouseDown={() => menu.hide()} />
    </>
  );
};

export default ComboNavMenu;
