const buttonId = (id: string) => `button-${id}`;

/**
 * Test ID constants used in core-ui.
 * These should (will) be referenced in e2e tests. Presently, I don't see a necessity for a
 * hierarchical structure on this object, so let's keep it flat for now.
 */
export default Object.freeze({
  // Dropdown Search
  DROPDOWN_CUSTOMER_SEARCH: 'dropdown-customer-search',
  DROPDOWN_ENTITY_GROUP_SEARCH: 'dropdown-entity-group-search',

  DROPDOWN_APPLICATION_SELECT: 'dropdown-application-select',
  // Connector Filters
  DROPDOWN_TIME_PERIOD_RESOLUTION: 'dropdown-time-period-resolution',

  BUTTON_USER_MENU: buttonId('user-menu'),
});

export type TestProps = {
  testId?: string;
};
