import { useEffect, useState } from 'react';
import { Maybe, noop } from '@tellurian/ts-utils';
import useEventCallback from '../../../../lettuce/utils/useEventCallback';
import useSingleton from '../../../../../utils/useSingleton';
import { UseSelectableItem } from './useListItemSingleSelection';

type ListItemMultiSelectionParams<T, K = T> = {
  getItemKey?: (item: T) => K;
  initialSelection?: T[];
  onChange?: (nextSelection: T[]) => void;
};

type ListItemMultiSelection<T> = {
  getSelection: () => T[];
  setSelection: (selection: T[], preventOnChange?: boolean) => void;
  toggleItemSelected: (item: T, preventOnChange?: boolean) => void;
  setItemSelected: (item: T, selected: boolean, preventOnChange?: boolean) => void;
  useSelectableItem: UseSelectableItem<T>;
};

const listItemMultiSelection = <T, K = T>({
  initialSelection = [],
  // @ts-expect-error Cannot define default conditional on type in TS. Having a default is still preferable here.
  getItemKey = item => item,
  onChange,
}: ListItemMultiSelectionParams<T, K>): ListItemMultiSelection<T> => {
  const selectionMap = new Map<K, T>();
  const itemCallbackRegistry = new Map<K, (isSelected: boolean) => void>();
  let lastSelection: Maybe<T[]> = undefined;

  const getSelection = () => {
    if (!lastSelection) {
      lastSelection = [...selectionMap.values()];
    }

    return lastSelection;
  };

  const setSelection = (selection: T[], preventOnChange = false) => {
    const oldKeys = [...selectionMap.keys()];
    selectionMap.clear();
    selection.forEach(item => {
      const key = getItemKey(item);
      selectionMap.set(key, item);
      itemCallbackRegistry.get(key)?.(true);
    });
    for (const key of oldKeys) {
      if (!selectionMap.has(key)) {
        itemCallbackRegistry.get(key)?.(false);
      }
    }
    lastSelection = selection;
    if (!preventOnChange) {
      onChange?.(selection);
    }
  };
  setSelection(initialSelection, true);

  const setItemSelected = (item: T, selected: boolean, preventOnChange = false) => {
    const itemKey = getItemKey(item);
    let hasOp = false;
    if (selectionMap.has(itemKey)) {
      if (!selected) {
        selectionMap.delete(itemKey);
        hasOp = true;
      }
    } else if (selected) {
      selectionMap.set(itemKey, item);
      hasOp = true;
    }

    if (hasOp) {
      lastSelection = undefined;
      itemCallbackRegistry.get(itemKey)?.(selected);
      if (!preventOnChange) {
        onChange?.(getSelection());
      }
    }
  };

  const toggleItemSelected = (item: T, preventOnChange = false) => {
    const isItemSelected = selectionMap.has(getItemKey(item));
    setItemSelected(item, !isItemSelected, preventOnChange);
  };

  const useSelectableItem = (item: T) => {
    const itemKey = getItemKey(item);
    const [isSelected, setSelected] = useState(selectionMap.has(itemKey));
    useEffect(() => {
      itemCallbackRegistry.set(itemKey, setSelected);
      return () => {
        itemCallbackRegistry.delete(itemKey);
      };
    }, [itemKey]);

    return { isSelected };
  };

  return {
    setItemSelected,
    toggleItemSelected,
    setSelection,
    getSelection,
    useSelectableItem,
  };
};

export type UseListItemMultiSelectionParams<T, K = T | string> = Pick<
  ListItemMultiSelectionParams<T, K>,
  'getItemKey' | 'onChange'
> & {
  initialSelection?: T[];
};

export type UseListItemMultiSelection<T> = Pick<
  ListItemMultiSelection<T>,
  'setSelection' | 'getSelection' | 'setItemSelected' | 'useSelectableItem' | 'toggleItemSelected'
>;

const useListItemMultiSelection = <T, K = T>({
  initialSelection = [],
  onChange: onChangeProp,
  ...rest
}: UseListItemMultiSelectionParams<T, K>): UseListItemMultiSelection<T> => {
  const onChange = useEventCallback(onChangeProp ?? noop);
  return useSingleton(() =>
    listItemMultiSelection<T, K>({
      onChange,
      initialSelection,
      ...rest,
    }),
  );
};

export default useListItemMultiSelection;
