import React from 'react';
import { Menu, MenuButton, useMenuState } from 'reakit/Menu';
import { Separator } from 'reakit/Separator';
import { ReactComponent as AdminIcon } from '../../assets/icons/single-neutral-actions-setting.svg';
import NavigationMenuItem from '../../components/ui/NavigationMenuItem';
import { ActionMenuItemWithShortcut } from '../../components/ui/ActionMenuItem';
import { useAuthorizationContext } from '../../components/security/AuthorizationContext';
import InvisibleMenuBackdrop from '../NavBar/InvisibleMenuBackdrop';
import { DisableAdminShortcutSpec } from '../../components/security/GlobalAdminPermissionKeyboardToggle';
import useToggleKeyboardShortcutHelpShortcut from '../../components/keyboardSupport/help/useToggleKeyboardShortcutHelp';
import { path } from '../../components/lettuce/crisp/routing/lib';
import AccountBoundAdminMenuItems from './AccountBoundAdminMenuItems';
import AccountDetails from './AccountDetails';
import buttonStyles from '../NavBar/NavbarButton.module.css';
import styles from './AdminMenu.module.css';

type AdminMenuProps = {
  accountId?: string;
};

const AdminMenu: React.FC<AdminMenuProps> = ({ accountId }) => {
  const { getHasGlobalAdminPermission, setIsGlobalAdminPermissionDisabled } =
    useAuthorizationContext();
  const isGlobalAdmin = getHasGlobalAdminPermission();
  const menu = useMenuState({ baseId: 'admin-menu', gutter: 6 });
  const toggleShortcutHelpShortcut = useToggleKeyboardShortcutHelpShortcut();

  if (!isGlobalAdmin) {
    return null;
  }

  return (
    <>
      <MenuButton {...menu} className={buttonStyles.button}>
        <AdminIcon height={21} width={21} title="Admin functions" />
      </MenuButton>
      <Menu {...menu} className={styles.adminMenu} aria-label="Admin menu">
        {accountId && (
          <>
            <AccountDetails accountId={accountId} />
            <Separator className={styles.separator} />
            <AccountBoundAdminMenuItems accountId={accountId} menu={menu} />
            <Separator className={styles.separator} />
          </>
        )}
        <NavigationMenuItem menu={menu} label="All accounts" to={path('Admin')()} />
        <NavigationMenuItem
          menu={menu}
          label="Source table labels"
          to={path('ManageSourceLabels')()}
        />
        <NavigationMenuItem
          menu={menu}
          label="Terms of service"
          to={path('TermsOfServiceVersions')()}
        />
        <ActionMenuItemWithShortcut
          menu={menu}
          keyboardShortcut={{
            ...DisableAdminShortcutSpec,
            fn: () => setIsGlobalAdminPermissionDisabled(true),
          }}
          label="View as non-admin"
          registerShortcut={false}
        />
        <ActionMenuItemWithShortcut
          menu={menu}
          keyboardShortcut={toggleShortcutHelpShortcut}
          registerShortcut={false}
          label="View keyboard shortcuts"
        />
      </Menu>

      <InvisibleMenuBackdrop menuIsVisible={menu.visible} onMouseDown={() => menu.hide()} />
    </>
  );
};

export default AdminMenu;
