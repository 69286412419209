import React from 'react';
import clsx from 'clsx';
import { ReactComponent as HelpIcon } from '../../assets/icons/help_20x20.svg';
import { MenuType, useResponsive } from '../../components/ui';
import { ExternalLinkTarget } from '../../components/lettuce/components/ExternalLink';
import AdminMenu from '../AccountTemplate/AdminMenu';
import { Feature } from '../../utils/features';
import { useZendeskWidgetContext } from '../../components/zendeskWidget/Context';
import NavbarButton from './NavbarButton';
import MainMenu, { MenuOption } from './MainMenu';
import { BasicNavBar } from './BasicNavBar';
import ComboNavMenu from './ComboNavMenu';
import styles from './index.module.css';

export type NavBarProps = {
  accountId?: string;
  homeUri?: string;
  isMenuOptionSelected?: (menuOption: MenuOption, pathname: string) => boolean;
  menuOptions?: MenuOption[];
  condensed?: boolean;
  lhs?: React.ReactNode;
  rhs?: React.ReactNode;
};

export default function NavBar({
  accountId,
  homeUri,
  isMenuOptionSelected,
  menuOptions,
  condensed,
  lhs,
  rhs,
}: NavBarProps) {
  const { isMaxSmallScreen, isMaxMediumScreen } = useResponsive();
  const { helpLink } = useZendeskWidgetContext();

  return (
    <BasicNavBar homeUri={homeUri}>
      {!isMaxMediumScreen && !condensed && menuOptions && (
        <MainMenu
          isMenuOptionSelected={isMenuOptionSelected}
          menuOptions={menuOptions}
          menuType={MenuType.HORIZONTAL}
        />
      )}
      <div className={clsx(styles.body, { [styles.smallGap]: isMaxSmallScreen })}>
        <div className={styles.lhs}>{lhs}</div>
        <div className={styles.rhs}>
          {rhs}
          <div className={styles.rhsMenuGroup}>
            {helpLink && (
              <NavbarButton
                href={helpLink}
                title="Knowledge base"
                label="Help"
                target={ExternalLinkTarget.knowledgeBase}
                feature={Feature.NavMenuKnowledgeBase}
                Icon={HelpIcon}
              />
            )}
            <AdminMenu accountId={accountId} />
            <ComboNavMenu accountId={accountId} />
          </div>
        </div>
      </div>
    </BasicNavBar>
  );
}
NavBar.defaultProps = {
  condensed: false,
};
