import React from 'react';
import ReactDOM from 'react-dom/client';
import './utils/wdyr';
import './animations.css';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import setup from './setup';
import UnfiEmbeddedApp from './components/UnfiEmbeddedApp';

setup();

// Temporary until UNFI has changed the redirect URI on their end.
const isUnfiInsights = pathname =>
  pathname.startsWith('/unfi-insights-embedded') || pathname.startsWith('/unfi-fuel-embedded');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    {isUnfiInsights(window.location.pathname) ? <UnfiEmbeddedApp /> : <App />}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
