import React, { ReactNode } from 'react';
import { Header } from '../../components/ui';
import { useThemeContext } from '../../components/lettuce/components/Theme/ThemeProvider';
import { NavBarLogo } from './NavBarLogo';
import SkipToMainLink from './SkipToMainLink';
import styles from './BasicNavBar.module.css';

type BasicNavBarProps = {
  children: ReactNode;
  homeUri?: string;
};

export function BasicNavBar({ homeUri, children }: BasicNavBarProps) {
  const { isLettuceTheme } = useThemeContext();
  return (
    <Header className={styles.mainCrispNavBar}>
      <SkipToMainLink />
      {!isLettuceTheme && <NavBarLogo logoLinkUrl={homeUri} />}
      {children}
    </Header>
  );
}
