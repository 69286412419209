import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { Popover, PopoverDisclosure, usePopoverState } from 'reakit/Popover';
import { isEqual } from 'lodash';
import { ReactComponent as IconArrowDown } from '../../../../assets/icons/Arrow-Down-Line-Black-24.svg';
import { Option } from '../../lib';
import { SearchInput } from '../../formInputs';
import { useDefaultHashId } from '../../../lib';
import ListOfItemsSelect from '../../lists/listOfItems/ListOfItemsSelect';
import useSearchListOfItems from '../../lists/listOfItems/useSearchListOfItems';
import ListItemFocusHighlight from '../../lists/listOfItems/listItems/ListItemFocusHighlight';
import { Button, ButtonStyle } from '../../Button';
import { RenderSelectableItemProps } from '../../lists/listOfItems/SelectableListItem';
import CheckboxIcon from '../../CheckboxIcon';
import useDebounce from '../../../../utils/useDebounce';
import SelectedOption from '../../lists/SearchListOfOptions/SelectedOption';
import useListOfOptionsMultiSelect, {
  OptionWithMaybeSelectAll,
  useListOfOptionsMultiSelectAll,
} from '../../lists/options/useListOfOptionsMultiSelect';
import { useDismissPopoverOnOutsideMouseDown } from './lib';
import { Field, FieldProps } from '.';
import style from './MultiSelectField.module.css';

type MultiSelectProps = {
  options: Option<string>[];
  value?: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  searchable?: boolean;
  id?: string;
  popoverClassName?: string;
  selectAllEnabled?: boolean;
  showSelectedAsPills?: boolean;
  // Defines the values for options that should always be visible in the list,
  // even when filtering results due to a search
  alwaysVisibleOptionValues?: string[];
};

const getItemKey = (item: OptionWithMaybeSelectAll) =>
  item.kind === 'select all' ? 'select all' : item.value;

type SelectAllProps = {
  allSelected: boolean;
  anySelected: boolean;
};

const RenderItem: React.FC<
  RenderSelectableItemProps<OptionWithMaybeSelectAll> & { extraItemProps: SelectAllProps }
> = React.memo(
  function RenderItem({
    item,
    isSelected,
    extraItemProps: { anySelected, allSelected },
  }: RenderSelectableItemProps<OptionWithMaybeSelectAll> & { extraItemProps: SelectAllProps }) {
    const { text, kind } = item;

    if (kind === 'select all') {
      return (
        <div className={clsx(style.item, style.selectAll)}>
          <CheckboxIcon checked={allSelected} indeterminate={anySelected} />
          <div>Select All</div>
        </div>
      );
    }

    return (
      <div className={style.item}>
        <CheckboxIcon checked={isSelected} />
        <div>{text}</div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.item?.kind === nextProps.item?.kind &&
    prevProps.item?.value === nextProps.item?.value &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.extraItemProps === nextProps.extraItemProps,
);

function MultiSelect({
  options,
  onChange,
  value = [],
  placeholder,
  disabled = false,
  label,
  searchable,
  id,
  popoverClassName,
  selectAllEnabled = false,
  showSelectedAsPills = false,
  alwaysVisibleOptionValues,
}: MultiSelectProps) {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query);
  const queryToUse = useMemo(() => debouncedQuery.trim().toLowerCase(), [debouncedQuery]);
  const [selectedItems, setSelectedItems] = useState<Option<string>[]>([]);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const originalQuery = useRef('');

  const lastSelection = useRef<Option<string>[]>([]);
  const initialSelection = useMemo(
    () => options.filter(o => value.indexOf(o.value) !== -1),
    [options, value],
  );

  const baseListId = useDefaultHashId(`multiselect-${id}-list`);
  const popoverId = useDefaultHashId(`multiselect-${id}-popover`);

  const popover = usePopoverState({ placement: 'bottom-start', baseId: popoverId });

  const { listId, getItemId, setFocusedIndex, searchProps } = useSearchListOfItems({
    baseListId,
  });

  useDismissPopoverOnOutsideMouseDown(popover);

  const optionsToFilter = showSelectedOnly ? selectedItems : options;
  const filteredOptions = useMemo(() => {
    return queryToUse
      ? optionsToFilter.filter(
          option =>
            alwaysVisibleOptionValues?.includes(option.value) ||
            option.text.toLowerCase().indexOf(queryToUse) !== -1,
        )
      : optionsToFilter;
  }, [optionsToFilter, queryToUse, alwaysVisibleOptionValues]);

  const multiSelectHook = selectAllEnabled
    ? useListOfOptionsMultiSelectAll
    : useListOfOptionsMultiSelect<Readonly<OptionWithMaybeSelectAll>>;
  const {
    getFocusParentProps,
    getSelection,
    setSelection,
    setFocusedIndex: setFocus,
    ...multiSelectProps
  } = multiSelectHook({
    items: filteredOptions,
    initialSelection,
    toggleOnSpace: !searchable,
    isListFocused: false,
    isListItemMouseDownDefaultPrevented: true,
    onSelectionChange: newValue => {
      onChange(newValue.map(option => option.value));
      setSelectedItems(newValue);
    },
    onFocusedIndexChange: setFocusedIndex,
    getItemKey,
    selectAllEnabled,
  });

  useEffect(() => {
    if (!isEqual(lastSelection.current, initialSelection)) {
      setSelection(initialSelection);
      lastSelection.current = initialSelection;
    }
  }, [initialSelection, setSelection]);

  useEffect(() => {
    setFocus(0);
  }, [queryToUse, setFocus]);

  /**
   * If switching to "show selected only" mode, clear the query.
   * If switching to "show all results" mode, pre-fill the previous query.
   */
  const toggleSelectionAndUpdateQuery = () => {
    if (!showSelectedOnly) {
      setQuery('');
      originalQuery.current = query;
    } else if (showSelectedOnly && !query) {
      setQuery(originalQuery.current);
    }

    setShowSelectedOnly(!showSelectedOnly);
  };

  const buttonText = getSelection()
    .map(option => option.text)
    .join(', ');

  const focusParentProps = useMemo(
    () => (searchable ? undefined : getFocusParentProps()),
    [getFocusParentProps, searchable],
  );

  const footerAction = showSelectedOnly
    ? 'Show all results'
    : (count => (count > 0 ? `Show ${getSelection().length} selected` : null))(
        getSelection().length,
      );

  const allSelected = getSelection().length === filteredOptions.length;
  const anySelected = getSelection().length > 0;

  return (
    <>
      {showSelectedAsPills && (
        <ul className={clsx('mln', style.selectedOptionsList)} aria-hidden>
          {allSelected ? (
            <SelectedOption className="mbs" option={{ id: '', value: 'All options selected' }} />
          ) : (
            selectedItems.map(item => (
              <SelectedOption
                className="mbs"
                key={item.value}
                option={{ id: item.value, value: item.text }}
                onRemove={() => {
                  setSelection(selectedItems.filter(x => x.value !== item.value));
                }}
              />
            ))
          )}
        </ul>
      )}
      <div className={style.container}>
        <PopoverDisclosure
          {...popover}
          disabled={disabled}
          className={clsx(style.multiSelectDropdown, {
            [style.multiSelectDropdownOpen]: popover.visible,
            [style.disabledField]: disabled,
          })}
        >
          {buttonText ? (
            <span className={style.multiSelectDropdownButtonText}>{buttonText}</span>
          ) : (
            <span className={style.placeholder}>{placeholder}</span>
          )}
          <IconArrowDown
            className={style.multiSelectArrow}
            width="10px"
            height="10px"
            style={{
              transform: popover.visible ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            aria-hidden
          />
        </PopoverDisclosure>
        <Popover
          {...popover}
          aria-label={label}
          className={clsx(style.multiSelectPopover, popoverClassName)}
        >
          {searchable && (
            <div className={style.searchInputContainer}>
              <SearchInput
                className={style.searchInput}
                value={query}
                onChange={setQuery}
                placeholder="Search"
                {...searchProps}
                {...getFocusParentProps()}
              />
            </div>
          )}
          {filteredOptions.length === 0 ? (
            <div className="mas">No results.</div>
          ) : (
            <>
              <ListOfItemsSelect
                {...focusParentProps}
                {...multiSelectProps}
                RenderItem={
                  RenderItem as React.FC<RenderSelectableItemProps<OptionWithMaybeSelectAll>>
                }
                RenderListItem={ListItemFocusHighlight}
                className={style.multiSelectList}
                id={listId}
                getItemKey={getItemKey}
                getItemId={getItemId}
                extraItemProps={{ anySelected, allSelected }}
              />
            </>
          )}
          {searchable && footerAction && (
            <div className={style.footer}>
              <Button
                btnStyle={ButtonStyle.LINK}
                onClick={toggleSelectionAndUpdateQuery}
                type="button"
              >
                {footerAction}
              </Button>
            </div>
          )}
        </Popover>
      </div>
    </>
  );
}

export type MultiSelectFieldProps = FieldProps &
  MultiSelectProps & {
    optionsLabel?: string;
    loading?: boolean;
  };

/**
 * A field for selection of multiple items. Renders a checklist within a dropdown.
 */
export function MultiSelectField({
  options,
  onChange,
  value = [],
  placeholder,
  disabled = false,
  optionsLabel = 'options',
  loading = false,
  searchable = false,
  popoverClassName,
  selectAllEnabled,
  showSelectedAsPills,
  alwaysVisibleOptionValues,
  ...fieldProps
}: MultiSelectFieldProps) {
  return (
    <Field {...fieldProps}>
      {options.length ? (
        <MultiSelect
          id={`${fieldProps.id}-select`}
          options={options}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          label={fieldProps.label}
          searchable={searchable}
          popoverClassName={popoverClassName}
          selectAllEnabled={selectAllEnabled}
          showSelectedAsPills={showSelectedAsPills}
          alwaysVisibleOptionValues={alwaysVisibleOptionValues}
        />
      ) : loading ? (
        <span className={style.loading}>Loading...</span>
      ) : (
        <span className={style.error}>No {optionsLabel} found.</span>
      )}
    </Field>
  );
}
