import React from 'react';
import { MenuStateReturn } from 'reakit/Menu';
import { useGetAccountQuery } from '../../generated/graphql';
import NavigationMenuItem, {
  ExternalNavigationMenuItem,
} from '../../components/ui/NavigationMenuItem';
import useIsFeatureFlagEnabled from '../../components/lettuce/common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../components/lettuce/common/featureFlags/KnownFlags';
import { Feature, trackFeature } from '../../utils/features';
import { getSalesforceAccountUrl } from '../../components/AccountManager/AccountDetails/General/lib';
import { path } from '../../components/lettuce/crisp/routing/lib';
import { AccountDetailsTab } from '../../components/lettuce/crisp/routing/Tabs';

type AccountBoundAdminMenuItemsProps = {
  accountId: string;
  menu: MenuStateReturn;
};

const accountDetailsPath = (params: { accountId: string; tab?: string; identifier?: string }) =>
  path('AccountDetails')(params);

const AccountBoundAdminMenuItems: React.FC<AccountBoundAdminMenuItemsProps> = ({
  accountId,
  menu,
}) => {
  const isInboundDataStatusEnabled = useIsFeatureFlagEnabled(KnownFlags.InboundDataStatus);
  const { data } = useGetAccountQuery({ variables: { accountId } });
  const salesforceAccountId = data?.account.salesforceAccountId;

  const onClickCloseMenu = () => {
    menu.hide();
  };
  return (
    <>
      <NavigationMenuItem
        menu={menu}
        label="Account settings"
        to={accountDetailsPath({
          accountId,
          tab: AccountDetailsTab.Overview,
        })}
        onClick={onClickCloseMenu}
      />
      <NavigationMenuItem
        menu={menu}
        label="Configure active connectors"
        to={accountDetailsPath({ accountId, tab: AccountDetailsTab.Connectors })}
        onClick={onClickCloseMenu}
      />
      {isInboundDataStatusEnabled ? (
        <NavigationMenuItem
          menu={menu}
          label="Inbound data status"
          to={path('InboundDataStatus')({ accountId })}
          onClick={() => {
            onClickCloseMenu();
            trackFeature(Feature.InboundDataStatusLinkClick, {
              from: 'Connectors admin menu',
            });
          }}
        />
      ) : null}
      {salesforceAccountId && (
        <ExternalNavigationMenuItem
          menu={menu}
          href={getSalesforceAccountUrl(salesforceAccountId)}
          label="Salesforce account"
          onClick={() => trackFeature(Feature.AdminMenuOpenSalesforceAccount)}
        />
      )}
    </>
  );
};

export default AccountBoundAdminMenuItems;
