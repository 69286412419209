import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/crisp-logo.svg';
import { useResponsive } from '../../components/ui/useResponsive';
import { path } from '../../components/lettuce/crisp/routing/lib';
import CondensedLogo from './CondensedLogo';
import styles from './NavBarLogo.module.css';

type NavBarLogoProps = {
  logoLinkUrl?: string;
};

export function NavBarLogo({ logoLinkUrl }: NavBarLogoProps) {
  const { isMaxSmallScreen } = useResponsive();
  const homeUri = path('Home')();

  return (
    <div>
      <Link to={logoLinkUrl || homeUri}>
        {isMaxSmallScreen ? <CondensedLogo /> : <Logo className={styles.logo} />}
      </Link>
    </div>
  );
}
