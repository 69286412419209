import React, { useEffect, useState } from 'react';
import {
  useUnfiInsightsConfirmEmailAddressVerificationTokenMutation,
  useUnfiInsightsGetPendingOnPlatformRequestQuery,
} from '../../../generated/graphql';
import { ReactComponent as Logo } from '../../../assets/crisp-logo.svg';
import { ReactComponent as IconConnect } from '../../../assets/icons/lettuce/navrail-connectors.svg';
import { ReactComponent as IconAddAccount } from '../../../assets/icons/lettuce/plus-circle.svg';
import Spinner from '../components/Spinner';
import 'firebase/compat/auth';
import Button, { ButtonVariant } from '../components/Button';
import { useAuthorizationContext } from '../../security/AuthorizationContext';
import { useParsedQuery } from '../../../utils/querystringUtils';
import Headline from '../components/Headline';
import PageTemplate from '../crisp/navigation/PageTemplate';
import MaxWidthLayout from '../components/layout/MaxWidthLayout';
import { unfiEmbeddedPath } from '../unfiInsightsEmbedded/routing/Routes';
import { path } from '../crisp/routing/lib';
import Panel from '../components/Panel';
import { Feature, trackFeature } from '../../../utils/features';
import { PendingOnPlatformAccess, RequestAccountPanel } from './RequestAccount';
import { VerifyEmailPanel } from './VerifyEmail';
import style from './UnfiInsightsOnPlatformVerification.module.css';

const LandingPage: React.FC = () => {
  const [showRequestAccount, setShowRequestAccount] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  return showRequestAccount ? (
    <div>
      <RequestAccountPanel showDetail={setShowRequestAccount} />
    </div>
  ) : showVerifyEmail ? (
    <div>
      <VerifyEmailPanel showDetail={setShowVerifyEmail} />
    </div>
  ) : (
    <>
      <div className={'ptm'}>
        <Headline size="M">With Crisp, you can do even more with UNFI Insights</Headline>
      </div>
      <div className={'pbm phl'}>
        <Panel className={style.primaryActionButton}>
          <div>
            <IconAddAccount />
            <Headline as="h2" size="M" className={style.headline}>
              Request your Crisp Account
            </Headline>

            <p>
              Included with UNFI Insights, you can use Crisp to re-name or group products and stores
              and export data into your BI tool of choice, including excel.
            </p>
          </div>
          <Button variant={ButtonVariant.Secondary} onClick={() => setShowRequestAccount(true)}>
            Request your Account
          </Button>
        </Panel>

        <Panel className={style.primaryActionButton}>
          <div>
            <IconConnect />
            <Headline as="h2" size="M" className={style.headline}>
              Already using Crisp?
            </Headline>
            <p>
              Link your existing account to view data from UNFI and your other retail partners in
              one place.
            </p>
          </div>
          <Button variant={ButtonVariant.Secondary} onClick={() => setShowVerifyEmail(true)}>
            Link your Account
          </Button>
        </Panel>
      </div>
      <div>
        <div className={style.panelFooter}>
          <Headline as="h2" size="S" className={style.headline}>
            What is Crisp?
          </Headline>
          <p>
            With Crisp, you can access data from 40+ retailer and distributor partners, including
            UNFI, Whole Foods, Target, Walmart, and Amazon, wherever you need it – and get
            user-friendly analytics to power sales, supply chain, and category management.
          </p>
          <Button
            variant={ButtonVariant.Secondary}
            onClick={() => {
              trackFeature(Feature.UnfiInsightsBookDemoClicked);
              window.open('https://www.gocrisp.com/unfi', '_blank');
            }}
          >
            Book a Demo
          </Button>
        </div>
      </div>
    </>
  );
};

export const UnfiInsightsOnPlatformVerification: React.FC = () => {
  const { firebaseUser } = useAuthorizationContext();
  const { token } = useParsedQuery<{ token: string }>();
  const hasVerifiedEmail = firebaseUser?.emailVerified;

  const [confirmToken, { data: confirmTokenResult }] =
    useUnfiInsightsConfirmEmailAddressVerificationTokenMutation();

  const { data, loading } = useUnfiInsightsGetPendingOnPlatformRequestQuery({
    skip: !hasVerifiedEmail,
  });
  const pendingRequest = data?.currentUser?.pendingUnfiInsightsOnPlatformAccessRequest;

  useEffect(() => {
    if (token) {
      confirmToken({
        variables: {
          input: {
            token,
          },
        },
      });
    }
  }, [token, confirmToken]);

  useEffect(() => {
    /**
     * The Firebase tokens (id and refresh tokens) are invalidated after we modify the Firebase user on the backend,
     * and the user must re-authenticate.
     *
     * To avoid prompting the user, we redirect to the embedded sign page, which will sign in the Firebase user using
     * Azure OAuth credentials, and then we redirect back to the Crisp Platform.
     */
    if (firebaseUser && confirmTokenResult && !hasVerifiedEmail) {
      window.location.replace(
        unfiEmbeddedPath('UnfiInsightsEmbeddedSignIn')() + '?redirectUri=' + path('Home')(),
      );
    } else if (hasVerifiedEmail && token) {
      window.location.replace(path('Home')());
    }
  }, [confirmTokenResult, hasVerifiedEmail, firebaseUser, token]);

  if (token) {
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <PageTemplate>
      <div className={style.body}>
        <MaxWidthLayout>
          <div className={style.logo}>
            <Logo />
          </div>
          <Panel className={style.container}>
            {!pendingRequest && <LandingPage />}
            {pendingRequest && <PendingOnPlatformAccess />}
          </Panel>
        </MaxWidthLayout>
      </div>
    </PageTemplate>
  );
};
