import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconCheck } from '../../../assets/icons/check_16x16.svg';
import { ReactComponent as IconCheckboxChecked } from '../../../assets/icons/lettuce/checkbox-checked.svg';
import { ReactComponent as IconCheckboxUnchecked } from '../../../assets/icons/lettuce/checkbox-unchecked.svg';
import { useThemeContext } from '../../lettuce/components/Theme/ThemeProvider';
import style from './index.module.css';

type CheckboxIconProps = {
  checked?: boolean;
  indeterminate?: boolean;
};

const CheckboxIcon: React.FC<CheckboxIconProps> = ({
  checked = false,
  indeterminate = false,
}: CheckboxIconProps) => {
  const { isLettuceTheme } = useThemeContext();

  if (isLettuceTheme) {
    return (
      <div className={clsx(style.checkboxIcon, { [style.checked]: checked })}>
        {checked ? <IconCheckboxChecked /> : <IconCheckboxUnchecked />}
      </div>
    );
  }

  return (
    <div
      className={clsx(style.checkbox, {
        [style.checked]: checked || indeterminate,
      })}
    >
      {checked ? <IconCheck /> : null}
    </div>
  );
};

export default CheckboxIcon;
