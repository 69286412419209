import React, { PropsWithChildren } from 'react';
import { AnyObject } from '@tellurian/ts-utils';
import clsx from 'clsx';
import SkipToMainLink from '../../../pageTemplates/NavBar/SkipToMainLink';
import { FCC } from '../../../utils/types';
import {
  NavRailContextProvider,
  NavRailContextProviderProps,
  useNavRailContext,
} from './NavRail/NavRailContextProvider';
import style from './NavRailPageTemplate.module.css';

const MainContent: FCC = ({ children }) => {
  const { isCompact, isNavRailReady } = useNavRailContext();
  return (
    isNavRailReady && (
      <div className={clsx(style.container, { [style.compact]: isCompact })}>{children}</div>
    )
  );
};

type NavRailPageTemplateProps<T extends AnyObject> = T & {
  RenderNavRail: React.FC<T>;
} & Pick<NavRailContextProviderProps, 'isInitiallyReady' | 'SubsidiaryContext'>;

function NavRailPageTemplate<T extends AnyObject = AnyObject>({
  children,
  RenderNavRail,
  isInitiallyReady,
  SubsidiaryContext,
  ...navRailProps
}: PropsWithChildren<NavRailPageTemplateProps<T>>) {
  return (
    <>
      <SkipToMainLink />
      <NavRailContextProvider
        isInitiallyReady={isInitiallyReady}
        SubsidiaryContext={SubsidiaryContext}
      >
        <RenderNavRail {...(navRailProps as unknown as T)} />
        <MainContent>{children}</MainContent>
      </NavRailContextProvider>
    </>
  );
}

export default NavRailPageTemplate;
