import React from 'react';
import { MenuProps } from 'reakit';
import { noop } from '@tellurian/ts-utils';
import useDropdown, { UseDropdownParams } from '../lib/useDropdown';
import { ActionMenuItemSpec } from '../Menu/lib';
import Popover from '../lib/Popover';
import Menu from '../Menu';
import PopoverActionContextProvider from '../NavRail/PopoverActionContext';
import FloatingPortal from '../lib/FloatingPortal';
import { ContextButtonProps } from '../lib/ContextButton';
import ThreeDotButton from './ThreeDotButton';

type ThreeDotMenuProps = {
  items: ActionMenuItemSpec[];
  'aria-labelledby'?: string;
} & Pick<UseDropdownParams, 'isInitiallyActive' | 'placement'> &
  Pick<MenuProps, 'aria-label'> &
  Pick<ContextButtonProps, 'variant'>;

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  'aria-label': ariaLabel = 'context menu',
  'aria-labelledby': ariaLabelledBy,
  items,
  placement = 'end',
  variant,
  ...dropdownParams
}) => {
  const { isActive, getPopoverProps, getReferenceProps, setIsActive } = useDropdown({
    ...dropdownParams,
    placement,
    dismissOnClickAway: true,
  });

  return (
    <>
      <ThreeDotButton {...getReferenceProps()} variant={variant} />
      {isActive && (
        <FloatingPortal>
          <Popover
            {...getPopoverProps()}
            role="menu"
            // Prefer aria-labelledby if it is set
            aria-label={ariaLabelledBy ? undefined : ariaLabel}
            aria-labelledby={ariaLabelledBy}
            focusTrap={true}
          >
            <div className="pas">
              <PopoverActionContextProvider
                show={() => setIsActive(true)}
                hide={() => setIsActive(false)}
                onContentChanged={noop}
              >
                <Menu items={items} as="div" />
              </PopoverActionContextProvider>
            </div>
          </Popover>
        </FloatingPortal>
      )}
    </>
  );
};

export default ThreeDotMenu;
