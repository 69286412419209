import React, { SVGProps } from 'react';
import { Button } from 'reakit/Button';
import { useResponsive } from '../../components/ui';
import { Feature, trackFeature } from '../../utils/features';
import styles from './NavbarButton.module.css';

type NavbarButtonProps = {
  href: string;
  Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: string;
  label?: string;
  target?: React.HTMLAttributeAnchorTarget;
  feature?: Feature;
};

const NavbarButton: React.FC<NavbarButtonProps> = ({
  href,
  title,
  label,
  Icon,
  target,
  feature,
}) => {
  const { isMaxMediumScreen } = useResponsive();
  const buttonText = !isMaxMediumScreen && label ? label : null;

  return (
    <Button
      as="a"
      className={styles.button}
      onClick={() => feature && trackFeature(feature)}
      href={href}
      title={title}
      target={target}
    >
      <Icon className={!isMaxMediumScreen ? 'mrs' : undefined} />
      {buttonText}
    </Button>
  );
};

export default NavbarButton;
