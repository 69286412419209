import { Maybe } from '@tellurian/ts-utils';
import {
  ConnectorForSourceDataFragment,
  ConnectorMetadataField,
  ConnectorMetadataTable,
  ConnectorMetadataVersion,
  FieldAggregation,
  FieldModelingType,
  FieldTransformation,
} from '../../generated/graphql';
import useIsOverflowing, {
  getIsAtScrollEnd,
  getIsAtScrollStart,
  getIsOverflowing,
  UseIsOverflowing,
} from '../../utils/useIsOverflowing';
import { TableIdentifier } from '../../modelDocumentation/ModelMetadataDocs/lib';
import { ColumnTypeLabel } from './ListSequence/options/SourceTableField';

export type SourceTable = Pick<
  ConnectorMetadataTable,
  | 'tableType'
  | 'displayName'
  | 'fullyQualifiedTableId'
  | 'allowExportFieldCustomization'
  | 'name'
  | 'id'
>;
export type SourceTableField = Pick<
  ConnectorMetadataField,
  'name' | 'modelingType' | 'availableAggregations' | 'availableTransformations'
>;
export type SourceConnectorVersion = Pick<
  ConnectorMetadataVersion,
  'minorVersion' | 'majorVersion' | 'metadataModuleId'
>;

export type SourceTableFieldConfiguration = {
  transformation?: FieldTransformation;
  aggregation?: FieldAggregation;
};

// Value of each enum entry is UI rendered text and should be sentence case
export enum SourceTableCategory {
  Normalized = 'Normalized',
  Denormalized = 'Denormalized',
  Analysis = 'Analysis',
  Harmonized = 'Harmonized',
  Source = 'Source',
  // This will ensure that the UI will not erroneously default to a particular category when a new
  // tableType is added and core-ui is not yet updated.
  Other = 'Other',
}

export const SourceTableCategoryDescription: Record<SourceTableCategory, string> = {
  Normalized: 'Data is deduplicated and standardized for consistency across connectors.',
  Denormalized:
    'Cleansed and harmonized  source data. These tables are flattened and generated from our normalized data.',
  Analysis:
    'Combines data from various source tables for the connector to provide you with additional metrics for deeper insights.',
  Harmonized: 'Combines data from various source connectors, so you can access overall metrics.',
  Source:
    'Original data from the supplier/distributor portal with some light data processing and deduplication.',
  Other: '',
};

export type SourceDataProperty = {
  connector: ConnectorForSourceDataFragment;
  version: SourceConnectorVersion;
  sourceTableCategory: SourceTableCategory;
  sourceTable: SourceTable;
  field: SourceTableField;
  fieldConfiguration: SourceTableFieldConfiguration;
};

export type SourceDataPropertyName = keyof SourceDataProperty;

export type SourceDataColumnProperties = {
  label: string;
  name: string;
  optionMinWidth: number;
};

export type GetSourceDataColumnProperties = (
  navSelection: NavSelection,
) => SourceDataColumnProperties;

export const SourceDataColumn: Record<
  Exclude<keyof SourceDataProperty, 'fieldConfiguration'>,
  SourceDataColumnProperties
> & { fieldConfiguration: GetSourceDataColumnProperties } = {
  connector: {
    label: 'Connectors',
    name: 'connector',
    optionMinWidth: 150,
  },
  version: {
    label: 'Versions',
    name: 'version',
    optionMinWidth: 124,
  },
  sourceTableCategory: {
    label: 'Table types',
    name: 'tableType',
    optionMinWidth: 150,
  },
  sourceTable: {
    label: 'Tables',
    name: 'sourceTable',
    optionMinWidth: 164,
  },
  field: {
    label: 'Columns',
    name: 'field',
    optionMinWidth: 216,
  },
  fieldConfiguration: ({ field }) => ({
    label: field?.modelingType ? ColumnTypeLabel[field.modelingType] : 'Field configuration',
    name: 'fieldConfiguration',
    optionMinWidth: 220,
  }),
};

export const SourceDataColumnSequence: SourceDataPropertyName[] = [
  'connector',
  'version',
  'sourceTableCategory',
  'sourceTable',
  'field',
  'fieldConfiguration',
];

export const nextDependentProperty = (
  property: SourceDataPropertyName,
): Maybe<SourceDataPropertyName> => {
  const index = SourceDataColumnSequence.findIndex(p => p === property);
  if (index > -1 && index < SourceDataColumnSequence.length - 1) {
    return SourceDataColumnSequence[index + 1];
  }

  return undefined;
};

export const previousDependentProperty = (
  property: SourceDataPropertyName,
): Maybe<SourceDataPropertyName> => {
  const index = SourceDataColumnSequence.findIndex(p => p === property);
  if (index > 0) {
    return SourceDataColumnSequence[index - 1];
  }

  return undefined;
};

export type FieldUnit = {
  field: SourceDataProperty['field'];
  fieldConfiguration?: SourceTableFieldConfiguration;
};

export type SourceTableUnit = {
  sourceTable: SourceDataProperty['sourceTable'];
  isGroupingApplicable: boolean;
  fields: FieldUnit[];
};

export type SourceDataSelectionUnit = {
  connector: SourceDataProperty['connector'];
  version: SourceDataProperty['version'];
  sourceTables: SourceTableUnit[];
};
export type SourceDataSelection = SourceDataSelectionUnit[];
export type NavSelection = Partial<SourceDataProperty>;

/**
 * Construct a new navSelection object based on the column an item has been selected in.
 * If a column is on the lhs of the presently selected item, then subsequent columns
 * will not be included in the selection. Conversely, the selection will add a column
 * to the selection if this is based on the existing nav selection.
 * @param src
 * @param update
 */
export const getNextNavSelection = <T extends keyof SourceDataProperty>(
  src: NavSelection,
  update: { property: T; value: SourceDataProperty[T] },
): Partial<SourceDataProperty> => {
  const { property, value } = update;
  const indexOfColumn = SourceDataColumnSequence.indexOf(property);
  return {
    ...SourceDataColumnSequence.reduce<Partial<Record<keyof SourceDataProperty, unknown>>>(
      (res, key, index) => {
        if (index < indexOfColumn) {
          res[key] = src[key];
        }
        return res;
      },
      {},
    ),
    [property]: value,
  } as Partial<SourceDataProperty>;
};

const replaceArrayItemAt = <T>(arr: T[], index: number, item: T): T[] => {
  if (index > -1) {
    const nextArr = arr.slice();
    nextArr.splice(index, 1, item);
    return nextArr;
  }

  return arr;
};

export const replaceArrayItem = <T>(
  arr: T[],
  itemToReplace: T,
  replacementItem: T,
  eq: (item1: T, item2: T) => boolean = Object.is,
): T[] =>
  replaceArrayItemAt(
    arr,
    arr.findIndex(item => eq(item, itemToReplace)),
    replacementItem,
  );

export const createSelectionUnit = (
  { connector, version }: Pick<SourceDataProperty, 'connector' | 'version'>,
  sourceTables: SourceDataSelectionUnit['sourceTables'] = [],
): SourceDataSelectionUnit => ({
  connector,
  version,
  sourceTables,
});

// export const createSourceTableUnit = (targetSelectionUnit: Maybe<SourceDataSelectionUnit>, sourceTableUnit: Pick<SourceDataProperty, 'ta'>)

type UseIsOverflowingAutoComputeOnRender = {
  isAtScrollStart: boolean;
  isAtScrollEnd: boolean;
  isOverflowing: boolean;
} & Pick<UseIsOverflowing, 'onScroll' | 'containerRef'>;

export const useIsOverflowingAutoComputeOnRender = (): UseIsOverflowingAutoComputeOnRender => {
  const {
    onScroll,
    containerRef,
    isAtScrollEnd: isAtScrollEndBase,
    isAtScrollStart: isAtScrollStartBase,
    isOverflowing: isOverflowingBase,
  } = useIsOverflowing();
  const res = { onScroll, containerRef };

  return containerRef.current
    ? {
        ...res,
        isAtScrollStart: isAtScrollStartBase || getIsAtScrollStart(containerRef.current),
        isAtScrollEnd: isAtScrollEndBase || getIsAtScrollEnd(containerRef.current),
        isOverflowing: isOverflowingBase || getIsOverflowing(containerRef.current),
      }
    : {
        ...res,
        isAtScrollStart: false,
        isAtScrollEnd: false,
        isOverflowing: false,
      };
};

const versionEquals = (v1: SourceConnectorVersion, v2: SourceConnectorVersion) =>
  v1.majorVersion === v2.majorVersion && v1.minorVersion === v2.minorVersion;

const fieldConfigurationEquals = (
  fc1: SourceTableFieldConfiguration,
  fc2: SourceTableFieldConfiguration,
) => fc1.transformation === fc2.transformation && fc1.aggregation === fc2.aggregation;

export type EqualsFn<T extends SourceDataPropertyName> = (
  e1: SourceDataProperty[T],
) => (e2: SourceDataProperty[T]) => boolean;

export type Equals = Readonly<{
  [T in keyof SourceDataProperty]: EqualsFn<T>;
}>;

export const equals: Equals = {
  connector: c1 => c2 => c1.id === c2.id,
  version: v1 => v2 => versionEquals(v1, v2),
  sourceTableCategory: c1 => c2 => c1 === c2,
  sourceTable: table1 => table2 => table1.fullyQualifiedTableId === table2.fullyQualifiedTableId,
  field: field1 => field2 => field1.name === field2.name,
  fieldConfiguration: fc1 => fc2 => fieldConfigurationEquals(fc1, fc2),
};

type EqualsProperty = Readonly<{
  [T in keyof SourceDataProperty]: (
    e1: SourceDataProperty[T],
  ) => (e2Container: Pick<SourceDataProperty, T>) => boolean;
}>;

export const equalsProperty: EqualsProperty = Object.fromEntries(
  Object.entries(equals).map(([propertyName, eq]) => [
    propertyName,
    e1 => e2Container => eq(e1)(e2Container[propertyName]),
  ]),
) as EqualsProperty;

export const not =
  <T extends unknown[]>(predicate: (...args: T) => boolean) =>
  (...args: T) =>
    !predicate(...args);

export const findSelectionUnit = (
  selection: SourceDataSelection,
  connector: SourceDataSelectionUnit['connector'],
  version?: SourceDataSelectionUnit['version'],
) => {
  const connectorPredicate = equals.connector(connector);
  const versionPredicate = version ? equals.version(version) : () => true;
  return selection.find(s => connectorPredicate(s.connector) && versionPredicate(s.version));
};

export const findSourceTableUnit = (
  selection: SourceDataSelection,
  connector: SourceDataSelectionUnit['connector'],
  version: SourceDataSelectionUnit['version'],
  sourceTable: SourceDataSelectionUnit['sourceTables'][0]['sourceTable'],
) => {
  const selectionUnit = findSelectionUnit(selection, connector, version);
  if (selectionUnit) {
    const predicate = equals.sourceTable(sourceTable);
    return selectionUnit.sourceTables.find(stu => predicate(stu.sourceTable));
  }

  return undefined;
};

export const isFieldGroupable = (field: SourceTableField): boolean =>
  field.modelingType === FieldModelingType.Dimension ||
  field.modelingType === FieldModelingType.TimeKey;

export const isFieldUnitGroupable = (fieldUnit: FieldUnit): boolean =>
  isFieldGroupable(fieldUnit.field);

const isFieldConfigurationEqual = (
  c1: Maybe<SourceTableFieldConfiguration>,
  c2: Maybe<SourceTableFieldConfiguration>,
): boolean => {
  if (c1 && c2) {
    return equals.fieldConfiguration(c1)(c2);
  }

  if (c1) {
    return !c1.transformation && (!c1.aggregation || c1.aggregation === FieldAggregation.Sum);
  }

  if (c2) {
    return !c2.transformation && (!c2.aggregation || c2.aggregation === FieldAggregation.Sum);
  }

  // c1 and c2 are both falsy
  return true;
};

const isFieldUnitEqual = (fieldUnit1: FieldUnit, fieldUnit2): boolean =>
  equals.field(fieldUnit1.field)(fieldUnit2.field) &&
  isFieldConfigurationEqual(fieldUnit1.fieldConfiguration, fieldUnit2.fieldConfiguration);

const isSourceTableUnitEqual = (
  sourceTableUnit1: SourceTableUnit,
  sourceTableUnit2: SourceTableUnit,
): boolean => {
  if (
    sourceTableUnit1.isGroupingApplicable !== sourceTableUnit2.isGroupingApplicable ||
    !equals.sourceTable(sourceTableUnit1.sourceTable)(sourceTableUnit2.sourceTable)
  ) {
    return false;
  }

  return (
    sourceTableUnit1.fields.length === sourceTableUnit2.fields.length &&
    sourceTableUnit1.fields.every(fieldUnit1 =>
      sourceTableUnit2.fields.find(fieldUnit2 => isFieldUnitEqual(fieldUnit1, fieldUnit2)),
    )
  );
};

const isSelectionUnitEqual = (
  selectionUnit1: SourceDataSelectionUnit,
  selectionUnit2: SourceDataSelectionUnit,
): boolean => {
  if (
    !equals.connector(selectionUnit1.connector)(selectionUnit2.connector) ||
    !equals.version(selectionUnit1.version)(selectionUnit2.version)
  ) {
    return false;
  }

  return (
    selectionUnit1.sourceTables.length === selectionUnit2.sourceTables.length &&
    selectionUnit1.sourceTables.every(sourceTableUnit1 =>
      selectionUnit2.sourceTables.find(sourceTableUnit2 =>
        isSourceTableUnitEqual(sourceTableUnit1, sourceTableUnit2),
      ),
    )
  );
};

export const isSourceDataSelectionEqual = (
  selection1: SourceDataSelection,
  selection2: SourceDataSelection,
): boolean => {
  if (selection1.length !== selection2.length) {
    return false;
  }

  return selection1.every(selectionUnit1 =>
    selection2.find(selectionUnit2 => isSelectionUnitEqual(selectionUnit1, selectionUnit2)),
  );
};

export const getTableIdentifier = (
  { metadataModuleId, minorVersion, majorVersion }: SourceDataProperty['version'],
  sourceTable: SourceDataProperty['sourceTable'],
): TableIdentifier => ({
  moduleId: metadataModuleId,
  moduleVersion: { majorVersion, minorVersion },
  tableId: sourceTable.id,
});

export const isInScope = (target: NavSelection, scope: NavSelection): boolean => {
  const { connector, version, sourceTableCategory, sourceTable, field } = target;
  if (connector) {
    if (!scope.connector || !equals.connector(connector)(scope.connector)) {
      return false;
    }

    if (version) {
      if (!scope.version || !equals.version(version)(scope.version)) {
        return false;
      }

      if (sourceTableCategory) {
        if (
          !scope.sourceTableCategory ||
          !equals.sourceTableCategory(sourceTableCategory)(scope.sourceTableCategory)
        ) {
          return false;
        }

        if (sourceTable) {
          if (!scope.sourceTable || !equals.sourceTable(sourceTable)(scope.sourceTable)) {
            return false;
          }

          if (field) {
            if (!scope.field || !equals.field(field)(scope.field)) {
              return false;
            }
          }
        }
      }
    }
  }

  return true;
};
