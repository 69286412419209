import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Maybe } from '@tellurian/ts-utils';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { FCC } from '../../utils/types';
import { useThemeContext } from '../lettuce/components/Theme/ThemeProvider';
import CrispApp from '../lettuce/crisp/CrispApp';
import styles from './BackLink.module.css';

type BackLinkProps = {
  toLink: string;
  toPageText?: string;
};

export const GenericBackLink: FCC<{ to: string }> = ({ children, to }) => {
  const { isLettuceTheme } = useThemeContext();
  if (isLettuceTheme) {
    return null;
  }

  return (
    <p>
      <Link to={to}>
        <>
          <BackIcon className={styles.arrowIcon} />
          {children}
        </>
      </Link>
    </p>
  );
};

export const BackLink: React.FC<BackLinkProps> = ({ toLink, toPageText }) => {
  return <GenericBackLink to={toLink}>Back to {toPageText}</GenericBackLink>;
};

export const BackHome: React.FC = () => {
  const {
    params: { accountId },
  } = useRouteMatch<{ accountId: Maybe<string> }>();
  const toLink = accountId ? CrispApp.path('AccountHome')({ accountId }) : CrispApp.path('Home')();
  return <GenericBackLink to={toLink}>Back home</GenericBackLink>;
};
