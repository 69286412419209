import React from 'react';
import PageTemplate, { PageTemplateProps } from '../index';
import ZendeskWidget from '../../components/zendeskWidget/ZendeskWidget';
import { AccountZendeskWidgetProps } from '../AccountTemplate';
import { FCC } from '../../utils/types';
import { useThemeContext } from '../../components/lettuce/components/Theme/ThemeProvider';
import GlobalAdminTemplate from '../../components/lettuce/crisp/templates/GlobalAdminTemplate';

type AccountManagerTemplateProps = PageTemplateProps;

const AccountManagerTemplate: FCC<AccountManagerTemplateProps> = ({ children, ...props }) => {
  return (
    <PageTemplate {...props}>
      {children}
      <ZendeskWidget hidden={true} {...AccountZendeskWidgetProps} />
    </PageTemplate>
  );
};

const ThemedAccountManagerTemplate: FCC<AccountManagerTemplateProps> = props => {
  const { isLettuceTheme } = useThemeContext();
  return isLettuceTheme ? (
    <GlobalAdminTemplate>{props.children}</GlobalAdminTemplate>
  ) : (
    <AccountManagerTemplate {...props} />
  );
};

export default ThemedAccountManagerTemplate;
