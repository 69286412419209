import React from 'react';
import clsx from 'clsx';
import { FCC } from '../../../../utils/types';
import style from './index.module.css'; // eslint-disable-line

const TextSizes = ['XS', 'S', 'M', 'L'] as const;
type TextSize = (typeof TextSizes)[number];

type TextProps = {
  subtle?: boolean;
  size?: TextSize;
  className?: string;
  block?: boolean;
  bold?: boolean;
};

const Text: FCC<TextProps> = ({
  children,
  size,
  subtle = false,
  className,
  block,
  bold = false,
}) => {
  const Component = block ? 'div' : 'span';
  return (
    <Component
      className={clsx(
        {
          [style.subtle]: subtle,
          [style.bold]: bold,
        },
        size && style[size],
        className,
      )}
    >
      {children}
    </Component>
  );
};

export default Text;
