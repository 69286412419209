import React from 'react';
import clsx from 'clsx';
import { FieldModelingType } from '../../../../generated/graphql';
import { SourceDataProperty } from '../../lib';
import style from './SourceTableField.module.css'; // eslint-disable-line
import Tooltip, { Placement, Trigger } from '../../../ui/Tooltip';
import { RenderCheckboxOptionProps } from '../../../ui/lists/ListOfCheckboxOptions/CheckboxOption';

export type SourceTableFieldContext = {
  isGroupingApplicable: boolean;
};

export const ColumnTypeLabel: Readonly<Record<FieldModelingType, string>> = {
  [FieldModelingType.Dimension]: 'Grouping dimension',
  [FieldModelingType.Measure]: 'Measure',
  [FieldModelingType.TimeKey]: 'Time key',
};

const getColumnTypeTooltipContent = (modelingType: FieldModelingType): string => {
  if (modelingType === FieldModelingType.TimeKey) {
    return `${ColumnTypeLabel[modelingType]} (must be included)`;
  }

  return ColumnTypeLabel[modelingType];
};

const BadgeContent: Readonly<Record<FieldModelingType, string>> = {
  [FieldModelingType.Measure]: 'SUM',
  [FieldModelingType.Dimension]: 'GROUP BY',
  [FieldModelingType.TimeKey]: 'GROUP BY',
};

type FieldBadgeProps = Pick<SourceTableFieldContext, 'isGroupingApplicable'> &
  Pick<SourceDataProperty['field'], 'modelingType'> &
  Pick<RenderCheckboxOptionProps<SourceDataProperty['field']>, 'isChecked'>;

const FieldBadge: React.FC<FieldBadgeProps> = ({
  isGroupingApplicable,
  modelingType,
  isChecked,
}) => {
  const badge = isGroupingApplicable && isChecked && modelingType && BadgeContent[modelingType];
  return badge ? (
    <div className={style.badge}>{badge}</div>
  ) : modelingType ? (
    <div className={style.badgePlaceholder}>{BadgeContent[modelingType]}</div>
  ) : null;
};

const SourceTableField: React.FC<
  RenderCheckboxOptionProps<SourceDataProperty['field'], SourceTableFieldContext>
> = ({ option, context, isChecked, isDisabled }) => {
  const { isGroupingApplicable } = context;
  const { modelingType, name } = option;
  return (
    <div className={style.option}>
      <div className={clsx(style.lhs, { [style.disabled]: isDisabled })}>
        {modelingType && (
          <Tooltip
            trigger={Trigger.HOVER}
            placement={Placement.Top}
            content={getColumnTypeTooltipContent(modelingType)}
            parentNode={document.body}
          >
            {ref => <div ref={ref} className={style[modelingType?.toLowerCase()]} aria-hidden />}
          </Tooltip>
        )}
        <div>{name}</div>
        {modelingType && <span className="sr-only">({ColumnTypeLabel[modelingType]})</span>}
      </div>
      <FieldBadge
        isGroupingApplicable={isGroupingApplicable}
        isChecked={isChecked}
        modelingType={modelingType}
      />
    </div>
  );
};

export default SourceTableField;
