import React from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { BackHome, ContentWidth, MainContent, useResponsive } from '../../components/ui';
import NavBar, { NavBarProps } from '../NavBar';
import PageError from '../../components/errorHandling/PageError';
import PageMessage from '../../components/PageMessage';
import { AuthorityGrant } from '../../components/security/AuthorizationContext';
import { MenuOption } from '../NavBar/MainMenu';
import { NotFound } from '../../components/httpErrors';
import {
  RequireAccountGrant,
  RequireAccountGrantProps,
} from '../../components/security/RequireAccountGrant';
import SegmentUserAccountTracker from '../../components/SegmentUserAccountTracker';
import ZendeskWidget, {
  DefaultWidgetLocale,
  ZendeskWidgetProps,
} from '../../components/zendeskWidget/ZendeskWidget';
import PageContainer, { PageContainerProps } from '../PageContainer';
import useLastAccountId from '../useLastAccountId';
import { HelpRegistry } from '../../components/HelpRoutes';
import TermsOfServiceNotification from '../../components/TermsOfServiceNotification';
import { useThemeContext } from '../../components/lettuce/components/Theme/ThemeProvider';
import { default as NavRailPageTemplate } from '../../components/lettuce/crisp/navigation/PageTemplate';
import { FCC } from '../../utils/types';
import { path, routeIdFromPath } from '../../components/lettuce/crisp/routing/lib';

export type AccountTemplateProps = {
  children: React.ReactNode;
  contentWidth?: ContentWidth;
  homeUri?: string;
  isMenuOptionSelected?: (menuOption: MenuOption, pathname: string) => boolean;
  menuOptions?: MenuOption[];
  requiredGrant: AuthorityGrant;
  includeBottomPadding?: boolean;
  zendeskWidget?: ZendeskWidgetProps;
  zendeskWidgetIsHidden?: boolean;
  navBarOptions?: Pick<NavBarProps, 'condensed' | 'lhs' | 'rhs'>;
  includeHomeLink?: boolean;
} & Pick<RequireAccountGrantProps, 'RenderForbidden'> &
  PageContainerProps;

type MatchParams = {
  accountId?: string;
};

export const AccountZendeskWidgetProps: ZendeskWidgetProps = {
  widgetKey: process.env.REACT_APP_CRISP_SUPPORT_ZENDESK_WIDGET_KEY!,
  locale: DefaultWidgetLocale,
  // position is set by the CS in zendesk widget control panel. Since this is one setting only,
  // we need to override in various context to prevent obscuring content.
  position: 'right',
  offsetBottom: 3, // Adjustment to align with our PageActionBar buttons
};

/**
 * Standard layout for an account specific page. Will render the top nav-bar for an account and ensure that the account
 * has been on-boarded by default.
 *
 * @param props
 * @constructor
 */
function AccountTemplate({
  children,
  contentWidth,
  homeUri,
  isMenuOptionSelected,
  menuOptions,
  requiredGrant,
  RenderForbidden,
  zendeskWidget = AccountZendeskWidgetProps,
  zendeskWidgetIsHidden = false,
  navBarOptions,
  includeBottomPadding,
  includeHomeLink = false,
}: AccountTemplateProps) {
  const { isLettuceTheme } = useThemeContext();
  const { pathname } = useLocation();
  const { params } = useRouteMatch<MatchParams>();
  const { accountId } = params;
  const accountHomeUri = path('AccountHome')({ accountId });
  const { isMaxSmallScreen } = useResponsive();
  useLastAccountId(accountId);

  const routeId = routeIdFromPath(pathname);
  const routeSuggestions = (routeId && HelpRegistry[routeId]) as Maybe<string>;

  return accountId ? (
    <PageContainer includeBottomPadding={includeBottomPadding}>
      {isLettuceTheme ? (
        // Temp: Fix body pushed down due to header margin top
        <div style={{ height: 1 }} />
      ) : (
        <NavBar
          accountId={accountId}
          homeUri={homeUri || accountHomeUri}
          menuOptions={menuOptions}
          isMenuOptionSelected={isMenuOptionSelected}
          {...navBarOptions}
          rhs={navBarOptions?.rhs}
        />
      )}
      <main id="main">
        <MainContent>
          <PageError type="page" hideIfTypePresent="modal" />
        </MainContent>
        <MainContent width={contentWidth}>{includeHomeLink && <BackHome />}</MainContent>
        <RequireAccountGrant
          accountId={accountId}
          grant={requiredGrant}
          RenderForbidden={RenderForbidden}
        >
          <MainContent width={contentWidth}>
            <PageMessage />
          </MainContent>
          <MainContent width={contentWidth}>
            <div style={{ minHeight: 400 }}>{children}</div>
          </MainContent>
        </RequireAccountGrant>
      </main>
      <SegmentUserAccountTracker accountId={accountId} />
      <ZendeskWidget
        {...zendeskWidget}
        suggestions={zendeskWidget.suggestions ?? routeSuggestions}
        hidden={isMaxSmallScreen || zendeskWidgetIsHidden}
      />
      <TermsOfServiceNotification accountId={accountId} />
    </PageContainer>
  ) : (
    <NotFound />
  );
}

const ThemedAccountTemplate: FCC<AccountTemplateProps> = props => {
  const { isLettuceTheme } = useThemeContext();
  return isLettuceTheme ? (
    <NavRailPageTemplate>
      <AccountTemplate {...props} zendeskWidgetIsHidden={true} />
    </NavRailPageTemplate>
  ) : (
    <AccountTemplate {...props} />
  );
};

export default ThemedAccountTemplate;
