import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AdminAuthorityGrant } from '../../components/security/AuthorizationContext';
import Forbidden from '../../components/connectors/Forbidden';
import useInvalidCredentialsToast from '../../components/Toast/useInvalidCredentialsToast';
import AccountTemplate, { AccountTemplateProps } from './';

type ConnectorsTemplateProps = {
  children: React.ReactNode;
} & Pick<AccountTemplateProps, 'includeHomeLink'>;

type MatchParams = {
  accountId?: string;
};

export default function ConnectorsTemplate({ children, includeHomeLink }: ConnectorsTemplateProps) {
  const {
    params: { accountId },
  } = useRouteMatch<MatchParams>();

  useInvalidCredentialsToast(accountId);

  return (
    <AccountTemplate
      requiredGrant={AdminAuthorityGrant}
      RenderForbidden={Forbidden}
      includeHomeLink={includeHomeLink}
    >
      {children}
    </AccountTemplate>
  );
}
