/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import React, { ReactElement, useMemo } from 'react';
import { Controller, ControllerRenderProps, useFormContext, UseFormReturn } from 'react-hook-form';
import { Placement } from '@popperjs/core';
import _ from 'lodash';
import { Maybe } from '@tellurian/ts-utils';
import { TooltipContent } from '../Tooltip';
import { FCC } from '../../../utils/types';
import { useThemeContext } from '../../lettuce/components/Theme/ThemeProvider';
import { default as LettuceTextFormField } from '../../lettuce/components/formFields/TextFormField';
import { default as LettuceTextAreaFormField } from '../../lettuce/components/formFields/TextAreaFormField';
import LettuceSelectFormField from '../../lettuce/components/formFields/SelectFormField';
import { ValidationFn } from './validation';
import { createRegisterOptionsFromValidationFns, generateId } from './lib';
import {
  CheckboxField,
  CheckboxFieldProps,
  FileUploadField,
  FileUploadFieldProps,
  InputAddToListField,
  InputAddToListFieldProps,
  InputField,
  InputFieldProps,
  MultiSelectField,
  MultiSelectFieldProps,
  PasswordField,
  RadioGroupField,
  RadioGroupFieldProps,
  SelectField,
  SelectFieldProps,
  TextAreaField,
  TextAreaFieldProps,
} from './Fields';

type RenderResult = React.ReactElement<
  any,
  | string
  | ((
      props: any,
    ) => React.ReactElement<
      any,
      string | any | (new (props: any) => React.Component<any, any, any>)
    > | null)
  | (new (props: any) => React.Component<any, any, any>)
>;

type Render = (
  data: Omit<ControllerRenderProps, 'ref'> & {
    errorMessage?: string;
    id: string;
    triggerValidation: () => Promise<boolean>;
  },
) => RenderResult;

export type FormElementProps<T, Q = T> = {
  name: string;
  render: Render;
  defaultValue?: Q;
  validate?: ValidationFn<T> | ValidationFn<T>[];
  id?: string;
};

export type PartialFormElementProps<T, Q = T> = Omit<
  FormElementProps<T, Q>,
  'render' | 'defaultValue'
> &
  Partial<Pick<FormElementProps<T, Q>, 'defaultValue'>>;

export const FormElementWithControl = <T, Q = T>({
  name,
  defaultValue,
  validate,
  render,
  id,
  control,
  trigger,
}: FormElementProps<T, Q> & Pick<UseFormReturn, 'control' | 'trigger'>): ReactElement<
  any,
  any
> | null => {
  const registerOptions = useMemo(
    () =>
      validate
        ? createRegisterOptionsFromValidationFns(Array.isArray(validate) ? validate : [validate])
        : undefined,
    [validate],
  );

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref: _, ...field }, fieldState }) =>
        render({
          ...field,
          triggerValidation: () => trigger(field.name),
          errorMessage: fieldState.error?.message,
          id: id ?? generateId(field.name),
        })
      }
      rules={registerOptions}
    />
  );
};

/**
 * A wrapper whose purpose it is to register an input component of any kind with a `react-hook-form` instance.
 * This entails that the "child" component will receive its name, value, onChange, onBur and errorMessage props
 * from the form context and not via props passed down to FormElement itself.
 * @constructor
 */
export const FormElement = <T, Q = T>(
  props: FormElementProps<T, Q>,
): ReactElement<any, any> | null => {
  const { control, trigger } = useFormContext();
  return <FormElementWithControl<T, Q> {...props} control={control} trigger={trigger} />;
};

export type CommonFieldProps =
  | 'label'
  | 'description'
  | 'hidden'
  | 'type'
  | 'style'
  | 'autoComplete'
  | 'autoFocus'
  | 'placeholder'
  | 'disabled'
  | 'spellCheck'
  | 'isFocused'
  | 'className'
  | 'onFocus'
  | 'onBlur'
  | 'onPaste'
  | 'onChange';

type InputFieldPropsFormPick = Pick<InputFieldProps, CommonFieldProps>;

export type TextFormFieldProps = PartialFormElementProps<string> &
  InputFieldPropsFormPick & {
    secret?: boolean;
    tooltipContent?: TooltipContent;
    tooltipPlacement?: Placement;
    focused?: boolean;
    onChange?: (nextValue: string) => void;
  };

export const TextFormField: React.FC<TextFormFieldProps> = ({
  id,
  defaultValue = '',
  validate,
  name,
  secret = false,
  type,
  onChange: onChangeProp,
  ...inputFieldProps
}) => {
  const { isLettuceTheme } = useThemeContext();
  if (isLettuceTheme) {
    return (
      <LettuceTextFormField
        id={id}
        defaultValue={defaultValue}
        name={name}
        validate={validate}
        type={type}
        label={inputFieldProps.label ?? name}
        onChange={onChangeProp}
        isFocused={inputFieldProps.focused}
        {..._.pick(
          inputFieldProps,
          'isFocused',
          'onFocus',
          'disabled',
          'placeholder',
          'autoComplete',
          'className',
          'onPaste',
          'onBlur',
          'autoFocus',
          'data-testid',
        )}
      />
    );
  }
  return (
    <FormElement
      id={id}
      defaultValue={defaultValue}
      name={name}
      validate={validate}
      render={({ triggerValidation, onChange, ...renderProps }) => (
        <InputField
          type={secret ? 'password' : type}
          onChange={(nextValue: string) => {
            onChange(nextValue);
            onChangeProp?.(nextValue);
          }}
          {...renderProps}
          {...inputFieldProps}
        />
      )}
    />
  );
};

export const PasswordFormField: React.FC<
  Omit<TextFormFieldProps, 'type' | 'name' | 'secret'> & { name?: string }
> = ({ id, defaultValue = '', validate, name, onChange: onChangeProp, ...inputFieldProps }) => {
  return (
    <FormElement
      id={id}
      defaultValue={defaultValue}
      name={name ?? 'password'}
      validate={validate}
      render={({ triggerValidation, onChange, ...renderProps }) => (
        <PasswordField
          onChange={(nextValue: string) => {
            onChange(nextValue);
            onChangeProp?.(nextValue);
          }}
          {...renderProps}
          {...inputFieldProps}
        />
      )}
    />
  );
};

export type TextAreaFormFieldProps = Omit<FormElementProps<string>, 'render' | 'defaultValue'> &
  Partial<Pick<FormElementProps<string>, 'defaultValue'>> &
  Omit<TextAreaFieldProps, 'value'>;

export const TextAreaFormField: React.FC<TextAreaFormFieldProps> = ({
  id,
  defaultValue = '',
  validate,
  name,
  ...inputFieldProps
}) => {
  const { isLettuceTheme } = useThemeContext();
  if (isLettuceTheme) {
    return (
      <LettuceTextAreaFormField
        id={id}
        defaultValue={defaultValue}
        name={name}
        validate={validate}
        label={inputFieldProps.label ?? name}
        {..._.pick(inputFieldProps, 'isFocused', 'disabled', 'placeholder', 'autoComplete')}
      />
    );
  }
  return (
    <FormElement
      id={id}
      defaultValue={defaultValue}
      name={name}
      validate={validate}
      render={({ triggerValidation, ...renderProps }) => (
        <TextAreaField {...renderProps} {...inputFieldProps} />
      )}
    />
  );
};

export type InputAddToListFormFieldProps = Omit<
  FormElementProps<string[]>,
  'render' | 'defaultValue'
> &
  Partial<Pick<FormElementProps<string[]>, 'defaultValue'>> &
  Omit<InputAddToListFieldProps, 'value' | 'errorMessage'>;

export const InputAddToListFormField: React.FC<InputAddToListFormFieldProps> = ({
  id,
  validate,
  defaultValue = [],
  name,
  onChange,
  ...fieldProps
}) => {
  const formElementProps = { id, defaultValue, name, validate };
  return (
    <FormElement
      {...formElementProps}
      render={({ triggerValidation, ...renderProps }) => (
        <InputAddToListField
          {...fieldProps}
          {...renderProps}
          onChange={nextValue => {
            renderProps.onChange(nextValue);
            onChange?.(nextValue);
          }}
        />
      )}
    />
  );
};

type RadioGroupFormFieldProps = Omit<FormElementProps<string>, 'render' | 'defaultValue'> &
  Partial<Pick<FormElementProps<string>, 'defaultValue'>> &
  Omit<RadioGroupFieldProps, 'onChange'> & {
    focused?: boolean;
  };

export const RadioGroupFormField: FCC<RadioGroupFormFieldProps> = ({
  name,
  validate,
  defaultValue,
  children,
  ...fieldProps
}) => {
  const formElementProps = { defaultValue, name, validate };
  return (
    <FormElement
      {...formElementProps}
      render={({ triggerValidation, ...renderProps }) => {
        return <RadioGroupField {...fieldProps} {...renderProps} />;
      }}
    />
  );
};

export type MultiSelectFormFieldProps = Omit<
  FormElementProps<string[]>,
  'render' | 'defaultValue'
> &
  Partial<Pick<FormElementProps<string[]>, 'defaultValue'>> &
  Omit<MultiSelectFieldProps, 'onChange' | 'value'> & {
    focused?: boolean;
    searchable?: boolean;
  };
/**
 * A form field for selection of multiple items. Renders a checklist within a dropdown.
 */
export const MultiSelectFormField: React.FC<MultiSelectFormFieldProps> = ({
  name,
  validate,
  defaultValue,
  ...fieldProps
}) => {
  const formElementProps = { defaultValue, name, validate };
  return (
    <FormElement
      {...formElementProps}
      render={({ triggerValidation, ...renderProps }) => (
        <MultiSelectField {...fieldProps} {...renderProps} />
      )}
    />
  );
};

export type CheckboxFormFieldProps = PartialFormElementProps<boolean> &
  Omit<CheckboxFieldProps, 'defaultValue'>;

export const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({
  name,
  validate,
  defaultValue = false,
  ...fieldProps
}) => {
  const formElementProps = { defaultValue, name, validate };
  return (
    <FormElement
      {...formElementProps}
      render={({ triggerValidation, ...renderProps }) => (
        <CheckboxField {...fieldProps} {...renderProps} checked={renderProps.value} />
      )}
    />
  );
};

export type FileUploadFormFieldProps = PartialFormElementProps<Maybe<File>> &
  Omit<FileUploadFieldProps, 'onChange' | 'value'>;

export const FileUploadFormField: React.FC<FileUploadFormFieldProps> = ({
  name,
  validate,
  defaultValue = false,
  ...fieldProps
}) => {
  const formElementProps = { defaultValue, name, validate };
  return (
    <FormElement
      {...formElementProps}
      render={({ triggerValidation, ...renderProps }) => (
        <FileUploadField {...fieldProps} {...renderProps} />
      )}
    />
  );
};
