import React from 'react';

import { ReactComponent as IconKnowledgeBase } from '../../../../assets/icons/lettuce/unfi/knowledgebase.svg';
import { ReactComponent as IconAcademy } from '../../../../assets/icons/lettuce/unfi/academy.svg';
import { ReactComponent as IconUpdates } from '../../../../assets/icons/lettuce/unfi/updates.svg';
import { ResourceType } from '../../components/Resources';

export type PartnerIdParams = {
  partnerId: string;
};

const {
  REACT_APP_UNFI_INSIGHTS_HELP_URL,
  REACT_APP_UNFI_INSIGHTS_ACADEMY_URL,
  REACT_APP_UNFI_INSIGHTS_UPDATES_URL,
} = process.env;

export const resources: Array<ResourceType> = [
  {
    title: 'Knowledge Base',
    subTitle: 'Dive into our detailed articles to solve your queries.',
    buttonText: 'Explore Now',
    url: REACT_APP_UNFI_INSIGHTS_HELP_URL,
    icon: <IconKnowledgeBase />,
  },
  {
    title: 'Academy',
    subTitle: 'Master our product with comprehensive tutorials in our Academy.',
    buttonText: 'Start Learning',
    url: REACT_APP_UNFI_INSIGHTS_ACADEMY_URL,
    icon: <IconAcademy />,
  },
  {
    title: 'Recent Updates',
    subTitle: 'Stay up-to-date with our latest features and improvements.',
    buttonText: 'See Updates',
    url: REACT_APP_UNFI_INSIGHTS_UPDATES_URL,
    icon: <IconUpdates />,
  },
];
