import { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Maybe } from '@tellurian/ts-utils';
import { KnownFlags } from '../../common/featureFlags/KnownFlags';

export type RouteOptions = {
  requireAuth?: boolean;
  requireFeatureFlag?: KnownFlags;
};

type FunctionComponentRoute = {
  (
    // eslint-disable-next-line
    props: RouteComponentProps<any>,
  ): ReactNode;
};

export type RouteDeclaration = {
  id: string;
  path: string;
  Render: FunctionComponentRoute;
} & RouteOptions;

export type RouteIdToRoute<T extends string> = { [key in T]: RouteDeclaration };

type RouteParam = Maybe<string | number | boolean>;
export type RouteParams = Record<string, RouteParam>;

export const encodeRouteParams = (params: RouteParams): Record<string, string> =>
  Object.entries(params).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: v || v === 0 || v === false ? encodeURIComponent(String(v)) : v,
    }),
    {},
  );

export type RedirectMap = Readonly<Record<string, string>>;
