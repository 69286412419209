import React from 'react';
import { Maybe, noop } from '@tellurian/ts-utils';
import AccountTemplate from '../templates/AccountTemplate';
import { ViewerAuthorityGrant } from '../../../security/AuthorizationContext';
import ModelDocumentation from '../../../../modelDocumentation';
import { NavBarProps } from '../../../../pageTemplates/NavBar';
import { useTableIdentifierQueryParameterState } from '../../../../modelDocumentation/lib';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import { lazyWithRetry } from '../../../lib';
import { AccountIdMatchParams } from './lib';
export type DocsNavBarOptions = Maybe<Pick<NavBarProps, 'lhs' | 'rhs'>>;

const RenderModelDocumentation = lazyWithRetry<typeof ModelDocumentation>(
  () => import(/* webpackChunkName: "accountDataCatalog" */ '../../../../modelDocumentation'),
);

export default function AccountDataCatalogPage({
  match: {
    params: { accountId },
  },
}: AccountIdMatchParams) {
  const tableIdentifierProps = useTableIdentifierQueryParameterState();
  const floatingMargin = tableIdentifierProps.selectedTableIdentifier;

  return (
    <AccountTemplate
      requiredGrant={ViewerAuthorityGrant}
      Layout={floatingMargin ? 'div' : MaxWidthLayout}
    >
      <RenderModelDocumentation
        accountId={accountId}
        setNavBarOptions={noop}
        {...tableIdentifierProps}
      />
    </AccountTemplate>
  );
}
