import React from 'react';
import clsx from 'clsx';
import { useResponsive } from '../useResponsive';
import { Modal } from '../Modal';
import { Backdrop } from '../Backdrop';
import { keyDownTriggerOnClick } from '../lib';
import styles from './OverlayContainer.module.css';

export enum OverlayPosition {
  Left,
  Right,
}

export type OverlayContainerProps = {
  children: React.ReactNode;
  disableResponsive: boolean;
  onClose: () => void;
  overlayPosition: OverlayPosition;
  closeOnContentClick?: boolean;
  closeOnContentMouseDown?: boolean;
  minWidth?: number;
  className?: string;
  overlayContainerStyle?: React.CSSProperties;
};

export default function OverlayContainer({
  children,
  closeOnContentClick = false,
  closeOnContentMouseDown = false,
  disableResponsive,
  onClose,
  overlayPosition,
  minWidth,
  className,
  overlayContainerStyle,
}: OverlayContainerProps) {
  const { isMaxSmallScreen } = useResponsive();

  if (!disableResponsive && isMaxSmallScreen)
    return <Modal onCloseClick={onClose}>{children}</Modal>;

  return (
    <div className={styles.overlayContainer}>
      <Backdrop onMouseDown={onClose} />
      {/* This is just closing the dropdown when something is selected, it is not the primary element to be interacted with */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={clsx(
          styles.overlay,
          {
            [styles.overlayLeft]: overlayPosition === OverlayPosition.Left,
            [styles.overlayRight]: overlayPosition === OverlayPosition.Right,
          },
          className,
        )}
        onClick={closeOnContentClick ? onClose : undefined}
        onMouseDown={closeOnContentMouseDown ? onClose : undefined}
        onKeyDown={e => {
          closeOnContentClick && keyDownTriggerOnClick(onClose)(e);
        }}
        style={{ ...overlayContainerStyle, minWidth }}
      >
        {children}
      </div>
    </div>
  );
}

OverlayContainer.defaultProps = {
  disableResponsive: false,
  overlayPosition: OverlayPosition.Left,
  top: 0,
};

export const OverlayContainerWithDarkBorder = ({ ...props }: OverlayContainerProps) => (
  <OverlayContainer
    overlayContainerStyle={{
      borderColor: '#aaa',
      borderRadius: '2px',
    }}
    {...props}
  />
);
