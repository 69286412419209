import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as ChartIcon } from '../../../../assets/icons/lettuce/chart.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/lettuce/help.svg';
import { ReactComponent as IconResources } from '../../../../assets/icons/lettuce/unfi/resources.svg';
import { Feature } from '../../../../utils/features';
import {
  ExternalLinkMenuListItem,
  MenuListItem,
  MenuListItemWithPopoverSubMenu,
} from '../../components/NavRail/menuItems';
import NavRail from '../../components/NavRail';
import { RouteId } from '../../crisp/routing/Routes';
import { path } from '../../crisp/routing/lib';
import { ExternalLinkTarget } from '../../components/ExternalLink';
import { useHelpUrl } from '../../crisp/navigation/HelpLinkContextProvider';
import CurrentUserAvatar from '../../components/Avatar/CurrentUserAvatar';
import AdditionalResources from '../Analytics/AdditionalResourcesCard';
import { SubMenuType } from '../../components/NavRail/SubMenuPopover';
import Title from '../../components/Title';
import { resources } from '../lib';
import UserSettingsMenu from './UserSettingsMenu';
import LogoLink from './LogoLink';
import style from './NavRailMenu.module.css';

type NavRailMenuProps = {
  partnerId: string;
};

const NavRailMenu: React.FC<NavRailMenuProps> = React.memo(function NavRailMenu({ partnerId }) {
  const {
    location: { pathname },
  } = useHistory();

  const getPathProps = useCallback(
    (routeId: RouteId) => {
      const p = path(routeId)({ partnerId });
      return {
        path: p,
        isSelected: pathname === p,
      };
    },
    [pathname, partnerId],
  );
  const helpUrl = useHelpUrl();

  const ResourcesMenuItemsWrapper = () => {
    return (
      <div className={style.resourcesMenuItemsWrapper}>
        <Title>Resources</Title>
        <AdditionalResources resources={resources} />
      </div>
    );
  };

  return (
    <NavRail alignChildren="space-between">
      <LogoLink partnerId={partnerId} />
      <ul id="nav-rail-pages" className={style.menuItems} role="menu">
        <MenuListItem
          Icon={ChartIcon}
          label="Analytics"
          featureId={Feature.NavMenuHomepage}
          {...getPathProps('PartnerMiniSiteHome')}
        />
        <MenuListItemWithPopoverSubMenu
          id="resourcesNav"
          RenderContent={ResourcesMenuItemsWrapper}
          RenderIcon={() => <IconResources />}
          label="Resources"
          subMenuType={SubMenuType.Expansive}
          ariaLabel="Resources Menu"
        />
        {helpUrl && (
          <ExternalLinkMenuListItem
            Icon={HelpIcon}
            label="Help"
            href={helpUrl}
            featureId={Feature.NavMenuKnowledgeBase}
            target={ExternalLinkTarget.knowledgeBase}
          />
        )}
      </ul>
      <ul id="nav-rail-settings">
        <MenuListItemWithPopoverSubMenu
          id="settingsMenu"
          RenderContent={UserSettingsMenu}
          RenderIcon={CurrentUserAvatar}
          featureId={Feature.NavMenuSettings}
          label="Settings"
          placement="end"
        />
      </ul>
    </NavRail>
  );
});

export default NavRailMenu;
