import { useCallback, useMemo } from 'react';
import { Key } from '../../../../utils/keyCodes';
import {
  UseFocusableItemIndex,
  useFocusableItemIndex,
  UseFocusableItemIndexParams,
} from './core/useFocusableItemIndex';
import useActionableItemIndex from './core/useActionableItemIndex';
import { ActionableListHooks, composeProps } from './lib';

export type UseListOfItemsActionableParams<T> = {
  items: readonly T[];
  initialFocusedIndex?: number;
  onFocusedIndexChange?: (nextIndex: number) => void;
  toggleOnSpace?: boolean;
  isListFocused?: boolean;
  isListItemMouseDownDefaultPrevented?: boolean;
  onItemToggle?: (item: T, index: number) => void;
} & Pick<UseFocusableItemIndexParams, 'getResetIndexOnItemCountChange'>;

export type UseListOfItemsActionable<T> = ActionableListHooks &
  Pick<
    UseFocusableItemIndex,
    'getFocusParentProps' | 'getFocusedIndex' | 'focusTransitionsEnabled' | 'setFocusedIndex'
  > & {
    // Pass-through - for convenience only
    items: readonly T[];
  };

const useListOfItemsActionable = <T>({
  items,
  onItemToggle,
  initialFocusedIndex = 0,
  onFocusedIndexChange,
  toggleOnSpace = true,
  isListFocused = false,
  isListItemMouseDownDefaultPrevented = false,
  getResetIndexOnItemCountChange,
}: UseListOfItemsActionableParams<T>): UseListOfItemsActionable<T> => {
  const {
    useFocusableItem,
    getFocusParentProps: getParentProps1,
    getFocusedIndex,
    setFocusedIndex,
    focusTransitionsEnabled,
  } = useFocusableItemIndex({
    initialFocusedIndex,
    itemCount: items.length,
    onChange: onFocusedIndexChange,
    initiallyActive: isListFocused,
    getResetIndexOnItemCountChange,
  });

  const { useActionableItem, getParentFocusProps: getParentProps2 } = useActionableItemIndex({
    isMouseDownDefaultPrevented: isListItemMouseDownDefaultPrevented,
    toggleOnKeys: useMemo(
      () => (toggleOnSpace ? [Key.Enter, Key.Space] : [Key.Enter]),
      [toggleOnSpace],
    ),
    // Memoization on the following is crucial to gain the performance boost when re-rendering the list on every focus change
    onToggle: useCallback(index => onItemToggle?.(items[index], index), [items, onItemToggle]),
    onToggleFocused: useCallback(() => {
      const index = getFocusedIndex();
      const itemToSelect = items[index];
      itemToSelect && onItemToggle?.(itemToSelect, index);
    }, [items, getFocusedIndex, onItemToggle]),
  });

  const getFocusParentProps = useMemo(() => {
    return () => composeProps(getParentProps1(), getParentProps2());
  }, [getParentProps1, getParentProps2]) as UseFocusableItemIndex['getFocusParentProps'];

  return {
    useFocusableItem,
    useActionableItem,
    focusTransitionsEnabled,
    getFocusedIndex,
    setFocusedIndex,
    getFocusParentProps,
    items,
  };
};

export default useListOfItemsActionable;
