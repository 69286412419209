import { useEffect } from 'react';
import { ZendeskWidgetOptions } from './lib';
import { useZendeskWidgetContext } from './Context';

export const DefaultWidgetLocale = navigator.language || 'en-US';

export type ZendeskWidgetProps = {
  widgetKey: string;
  suggestions?: string;
  hidden?: boolean;
} & ZendeskWidgetOptions;

const ZendeskWidget: React.FC<ZendeskWidgetProps> = ({
  widgetKey,
  hidden = false,
  suggestions,
  ...options
}) => {
  const { update, setSuggestions, hide, show } = useZendeskWidgetContext();

  useEffect(() => {
    if (!hidden) {
      update(widgetKey, options);
    }
  }, [widgetKey, update, options, hidden]);

  useEffect(() => {
    setSuggestions(suggestions);
  }, [setSuggestions, suggestions]);

  useEffect(() => {
    if (hidden) {
      hide();
    } else {
      show();
    }
  }, [hidden, hide, show]);

  return null;
};

export default ZendeskWidget;
