import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AuthenticationResult,
  AuthError as AzureAuthError,
  CacheLookupPolicy,
} from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import {
  AuthError as FirebaseAuthError,
  getAuth,
  linkWithCredential,
  OAuthProvider,
  signInWithCredential,
  signOut,
} from 'firebase/auth';
import _ from 'lodash';
import { useAuthenticationContext } from '../../../security/AuthenticationContext';
import { LocalStorageKey } from '../../../../utils/localStorage';
import useLocalStorageState from '../../../../utils/localStorage/useLocalStorageState';
import { withQueryParams } from '../../../../utils/querystringUtils';
import { unfiEmbeddedPath } from '../routing/Routes';
import CrispApp from '../../crisp/CrispApp';
import logger from '../../../../services/logger';
import {
  generateNonce,
  isLinked,
  toUnfiAuthError,
  UnfiAuthError,
  UnfiAuthState,
  providerInfo,
} from './lib';
import { useUnfiInsightsAccessToken } from './useUnfiInsightsAccessToken';
import { useFirebaseIdTokenResult } from './useFirebaseIdTokenResult';

export const useUnfiSignIn = ({ providerId, scopes }: { providerId: string; scopes: string[] }) => {
  const [nonce, setNonce, refetchNonce] = useLocalStorageState(
    LocalStorageKey.UnfiInsightsOAuthNonce,
    undefined,
  );
  const initialized = useRef(false);
  const hasSetRedirectPromise = useRef(false);
  const azureAuthResult = useRef<AuthenticationResult>();
  const idTokenRef = useRef<string>();

  const [internalState, setInternalState] = useState(UnfiAuthState.Loading);
  const [error, setError] = useState<UnfiAuthError>();

  const { firebaseUser, loading: firebaseLoading } = useAuthenticationContext();
  const { instance, inProgress } = useMsal();
  const activeAccount = useAccount();
  const { accessToken, setAccessToken } = useUnfiInsightsAccessToken();
  const firebaseIdTokenResult = useFirebaseIdTokenResult();
  const signInProvider = firebaseIdTokenResult?.signInProvider;

  const onFirebaseSuccess = useCallback(() => {
    setNonce(undefined);
    setInternalState(UnfiAuthState.Authenticated);
  }, [setInternalState, setNonce]);

  const onAzureError = useCallback(
    (error: AzureAuthError) => {
      if (error.errorCode === 'interaction_required' || error.errorCode === 'user_cancelled') {
        setInternalState(UnfiAuthState.LoginRequired);
      } else {
        logger.error('Azure error:', error);
        setError(toUnfiAuthError(error));
      }
    },
    [setInternalState],
  );

  const signInWithRedirect = useCallback(() => {
    const isInIframe = window !== window.top;

    /**
     * When embedded in an iframe, we cannot redirect to myUNFI for
     * authentication, since that would require myUNFI embedding itself.
     * Instead, signal the embedding page to do the redirect.
     */
    if (isInIframe) {
      window.top?.postMessage({ action: 'redirect', destination: 'returnToUnfiInsights' }, '*');
    } else {
      const nonce = generateNonce();
      setNonce(nonce);
      instance
        .loginRedirect({
          scopes: scopes,
          nonce: nonce.nonce,
          extraQueryParameters: { style: '/myUNFI' },
        })
        .catch(error => onAzureError(error));
    }
  }, [instance, setNonce, onAzureError, scopes]);

  const onFirebaseError = useCallback(
    (error: FirebaseAuthError) => {
      if (error.code === 'auth/account-exists-with-different-credential') {
        setInternalState(UnfiAuthState.UserAlreadyExists);
      } else if (error.code === 'auth/missing-or-invalid-nonce') {
        signInWithRedirect();
      } else {
        setNonce(undefined);
        logger.error('UnfiInsightsEmbedded - Firebase error:', error);
        setError(toUnfiAuthError(error));
      }
    },
    [setInternalState, setNonce, signInWithRedirect],
  );
  const firebaseSignIn = useCallback(
    (idToken: string, rawNonce: string) => {
      const provider = new OAuthProvider(providerId);
      scopes.forEach(scope => provider.addScope(scope));

      const credential = provider.credential({
        idToken: idToken,
        rawNonce: rawNonce,
      });
      signInWithCredential(getAuth(), credential).then(onFirebaseSuccess).catch(onFirebaseError);
    },
    [providerId, onFirebaseSuccess, onFirebaseError, scopes],
  );

  const linkUsers = useCallback(() => {
    setInternalState(UnfiAuthState.Loading);
    const idToken = idTokenRef.current;

    if (nonce && idToken) {
      if (firebaseUser) {
        const provider = new OAuthProvider(providerId);
        scopes.forEach(scope => provider.addScope(scope));
        const credential = provider.credential({
          idToken: idToken,
          rawNonce: nonce.rawNonce,
        });

        linkWithCredential(firebaseUser, credential).then(onFirebaseSuccess).catch(onFirebaseError);
      } else {
        setError({
          origin: 'link_users',
          code: 'missing_firebase_user',
          message: 'Missing Firebase user',
        });
      }
    } else {
      setInternalState(UnfiAuthState.LoginRequired);
    }
  }, [
    providerId,
    firebaseUser,
    onFirebaseSuccess,
    onFirebaseError,
    setInternalState,
    nonce,
    scopes,
  ]);

  const onAzureSuccess = useCallback(
    (result: AuthenticationResult) => {
      refetchNonce();
      const account = result.account;
      if (account) {
        instance.setActiveAccount(account);
        setAccessToken(result.accessToken);
        idTokenRef.current = result.idToken;
        const idTokenClaims = account.idTokenClaims || {};
        const firebaseEmail = firebaseUser?.email;
        const azureEmail = idTokenClaims['email'] as string | undefined;

        if (!firebaseUser && nonce) {
          firebaseSignIn(result.idToken, nonce.rawNonce);
        } else if (!firebaseUser) {
          signInWithRedirect();
        } else if (isLinked(providerId, account, firebaseUser)) {
          setInternalState(UnfiAuthState.Authenticated);
        } else if (
          firebaseEmail &&
          azureEmail &&
          firebaseEmail.toLowerCase() === azureEmail.toLowerCase()
        ) {
          linkUsers();
        } else {
          setInternalState(UnfiAuthState.SignedInWithAnotherUser);
        }
      } else {
        setError({
          origin: 'sign_in',
          code: 'missing_account',
          message: 'No account in response',
        });
      }
    },
    [
      instance,
      providerId,
      firebaseUser,
      firebaseSignIn,
      linkUsers,
      setInternalState,
      nonce,
      refetchNonce,
      signInWithRedirect,
      setAccessToken,
    ],
  );

  const continueWithUnfiUser = useCallback(() => {
    setInternalState(UnfiAuthState.Loading);
    const idToken = idTokenRef.current;

    if (nonce && idToken) {
      firebaseSignIn(idToken, nonce.rawNonce);
    } else {
      signInWithRedirect();
    }
  }, [firebaseSignIn, setInternalState, nonce, signInWithRedirect]);

  useEffect(() => {
    if (!hasSetRedirectPromise.current) {
      hasSetRedirectPromise.current = true;
      instance
        .handleRedirectPromise()
        .then((result: AuthenticationResult | null) => {
          if (result) {
            azureAuthResult.current = result;
          }
        })
        .catch(onAzureError);
    }
  }, [instance, onAzureError]);

  useEffect(() => {
    if (!initialized.current && !firebaseLoading && inProgress === 'none') {
      initialized.current = true;
      if (azureAuthResult.current) {
        onAzureSuccess(azureAuthResult.current);
      } else if (activeAccount) {
        instance
          .acquireTokenSilent({
            scopes: scopes,
            cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
          })
          .then((result: AuthenticationResult) => {
            azureAuthResult.current = result;
            onAzureSuccess(result);
          })
          .catch((error: AzureAuthError) => {
            if (
              error.errorCode === 'token_refresh_required' ||
              error.errorCode === 'invalid_grant'
            ) {
              signInWithRedirect();
            } else {
              onAzureError(error);
            }
          });
      } else {
        signInWithRedirect();
      }
    }
  }, [
    providerId,
    instance,
    firebaseLoading,
    inProgress,
    firebaseUser,
    activeAccount,
    firebaseSignIn,
    onAzureSuccess,
    onAzureError,
    setInternalState,
    setNonce,
    signInWithRedirect,
    scopes,
  ]);

  const state = useMemo(() => {
    const azureEmail = activeAccount?.idTokenClaims?.email as string | undefined;
    if (inProgress === 'none' && activeAccount && firebaseUser && accessToken && signInProvider) {
      // User is signed in with UNFI credentials and Firebase user is linked
      if (isLinked(providerId, activeAccount, firebaseUser) && signInProvider === providerId) {
        localStorage.removeItem('LinkProvider');
        return UnfiAuthState.Authenticated;
      }
      // Firebase user is not linked with UNFI credentials, but the email matches the authenticated UNFI user.
      // Show the loading screen while we link credentials.
      else if (
        signInProvider !== providerId &&
        azureEmail &&
        firebaseUser.email?.toLowerCase() === azureEmail?.toLowerCase() &&
        !providerInfo(firebaseUser, providerId)
      )
        return UnfiAuthState.Loading;
      // UNFI credentials does not match the Firebase user
      else return UnfiAuthState.FirebaseAzureUserMismatch;
    } else if (internalState === UnfiAuthState.Authenticated && (!firebaseUser || !activeAccount)) {
      return UnfiAuthState.LoginRequired;
    } else if (error) {
      return UnfiAuthState.Failed;
    } else {
      return internalState;
    }
  }, [
    activeAccount,
    firebaseUser,
    error,
    internalState,
    inProgress,
    accessToken,
    providerId,
    signInProvider,
  ]);

  useEffect(() => {
    if (state !== internalState) {
      setInternalState(state);
    }
  }, [state, internalState, setInternalState]);

  useEffect(() => {
    // Log out of Firebase and redirect to the sign-in flow
    if (state === UnfiAuthState.FirebaseAzureUserMismatch) {
      if (localStorage.getItem('isUnfiUserMergeInProgress')) {
        // If the user is attempting a merge, do not log out, instead wait for the local storage entry to clear and then refresh.
        const handler = (ev: StorageEvent) => {
          if (ev.key === 'isUnfiUserMergeInProgress' && ev.newValue === null) {
            // Reload when item has been removed
            window.location.reload();
          }
        };
        window.addEventListener('storage', handler);
        return () => window.removeEventListener('storage', handler);
      } else {
        signOut(getAuth()).then(() => {
          const path = withQueryParams({
            returnTo: `${location.pathname}${location.search}`,
          })(unfiEmbeddedPath('UnfiInsightsEmbeddedSignIn')());
          window.location.replace(path);
        });
      }
    }
  }, [state]);

  useEffect(() => {
    CrispApp.unfiInsightsEmbedded.getAzureUser = () => activeAccount || undefined;
    return () => {
      CrispApp.unfiInsightsEmbedded.getAzureUser = () => undefined;
    };
  }, [activeAccount]);

  useEffect(() => {
    CrispApp.unfiInsightsEmbedded.getFirebaseUser = () =>
      firebaseUser ? _.pick(firebaseUser, 'displayName', 'email', 'providerData') : undefined;
    return () => {
      CrispApp.unfiInsightsEmbedded.getFirebaseUser = () => undefined;
    };
  }, [firebaseUser]);

  return {
    state,
    error,
    azureUser: activeAccount,
    accessToken,
    nonce: nonce ? { nonce, rawNonce: nonce.rawNonce } : undefined,
    firebaseUser,
    linkUsers,
    continueWithUnfiUser,
    signInWithRedirect,
  };
};
