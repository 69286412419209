import { useGetAccountBusinessDashboardsEnabledQuery } from '../../../../generated/graphql';
import { useAuthorizationContext } from '../../../security/AuthorizationContext';
import useIsRemaAccountAdmin from '../../../../pageTemplates/NavBar/useIsRemaAccountAdmin';
import { useFeatureFlagsReady } from '../../common/featureFlags/FeatureFlags';
import { KnownFlags } from '../../common/featureFlags/KnownFlags';
import useIsFeatureFlagEnabled from '../../common/featureFlags/useIsFeatureFlagEnabled';
import { useHelpUrl } from './HelpLinkContextProvider';

const useNavigationProperties = (accountId?: string) => {
  const {
    getHasAccountReadPermission,
    getHasAccountAdminPermission,
    getHasGlobalAdminPermission,
    loading,
    isGlobalAdminPermissionDisabled,
  } = useAuthorizationContext();
  const isAccountAdmin = accountId ? getHasAccountAdminPermission(accountId) : false;
  const isAccountViewer = accountId ? getHasAccountReadPermission(accountId) : false;
  const isGlobalAdmin = getHasGlobalAdminPermission();
  const { data: businessDashboardsEnabledData, loading: loadingBusinessDashboardsQuery } =
    useGetAccountBusinessDashboardsEnabledQuery({
      variables: { accountId: accountId ?? '' },
      skip: isGlobalAdmin,
    });
  const businessDashboardsEnabled =
    isGlobalAdmin || !!businessDashboardsEnabledData?.account.businessDashboardsEnabled;
  const helpUrl = useHelpUrl();
  const isRemaAccountAdmin = useIsRemaAccountAdmin() && !isGlobalAdminPermissionDisabled;
  const isMdmPlusPlusEnabled = useIsFeatureFlagEnabled(KnownFlags.MdmPlusPlus);
  const isScheduledReportsEnabled = useIsFeatureFlagEnabled(KnownFlags.ScheduledReports);

  return {
    isAccountAdmin,
    isAccountViewer,
    businessDashboardsEnabled,
    helpUrl,
    isGlobalAdmin,
    isRemaAccountAdmin,
    isMdmPlusPlusEnabled,
    isScheduledReportsEnabled,
    loading: !useFeatureFlagsReady() || loading || loadingBusinessDashboardsQuery,
  };
};

export default useNavigationProperties;
