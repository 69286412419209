import React, { useMemo } from 'react';
import { maybe } from '@tellurian/ts-utils';
import {
  DropdownListOption,
  DropdownListOptions,
  getItemKey,
  getOptionSelectText,
  RenderListItem,
  toCanonicalOptions,
} from '../dropdownListOption';
import SelectDropdown, { SelectDropdownProps } from './index';

export type SelectOptionDropdownProps = {
  options: DropdownListOptions;
  selectedValue?: string;
  onChange: (nextValue: string) => void;
} & Pick<
  SelectDropdownProps<DropdownListOption>,
  | 'label'
  | 'placement'
  | 'backdrop'
  | 'popoverId'
  | 'getPopoverSizeProps'
  | 'hasError'
  | 'disabled'
  | 'onBlur'
  | 'isLoadingItems'
>;

const SelectOptionDropdown = React.forwardRef<HTMLInputElement, SelectOptionDropdownProps>(
  function SelectOptionDropdown(
    { options, onChange, selectedValue, ...rest }: SelectOptionDropdownProps,
    ref,
  ) {
    const items = useMemo(() => toCanonicalOptions(options), [options]);
    const selectedItem = useMemo(
      () =>
        selectedValue === undefined
          ? undefined
          : maybe(items.find(item => item.value === selectedValue)),
      [selectedValue, items],
    );

    return (
      <SelectDropdown<DropdownListOption>
        items={items}
        selectedItem={selectedItem}
        onChange={option => onChange(option.value)}
        RenderItem={RenderListItem}
        getItemKey={getItemKey}
        getInputText={getOptionSelectText('')}
        ref={ref}
        {...rest}
      />
    );
  },
);

export default SelectOptionDropdown;
