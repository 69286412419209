import React from 'react';
import { noop } from 'lodash';
import { Backdrop } from '../../components/ui';

type Props = {
  menuIsVisible: boolean;
  onMouseDown?: () => void;
};

/* For use with Reakit's Menu component.
   Draw an invisble backdrop to capture click events.
   Without this, clicking within the Looker iframe to close the menu will not work.
*/
const InvisibleMenuBackdrop: React.FC<Props> = ({ menuIsVisible, onMouseDown = noop }) => {
  return menuIsVisible ? (
    <Backdrop bgColor="rgba(255, 255, 255, 0)" onMouseDown={onMouseDown} />
  ) : null;
};

export default InvisibleMenuBackdrop;
