import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/lettuce/search.svg';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/lettuce/remove.svg';
import { IconButton } from '../Button';
import useAutoFocusOnce from '../lib/useAutoFocusOnce';
import useEventCallback from '../../utils/useEventCallback';
import style from './index.module.css';

export type SearchFieldProps = {
  onChange: (nextValue: string) => void;
  value: string;
  className?: string;
  placeholder?: string;
  focus?: boolean;
  // Pass this as true to focus immediately in a useEffect. By default focus is applied in a setTimeout
  // to prevent contention issues.
  immediateFocus?: boolean;
  minWidth?: number;
} & Pick<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'autoFocus' | 'onBlur' | 'onFocus' | 'onKeyDown'
>;

const SearchField: React.FC<SearchFieldProps> = ({
  onChange,
  value,
  focus = false,
  className,
  placeholder = 'Search',
  autoFocus: autoFocusProp = false,
  onBlur: onBlurProp,
  immediateFocus = false,
  minWidth,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { autoFocus, onBlur } = useAutoFocusOnce(autoFocusProp);
  useEffect(() => {
    if (autoFocus || focus) {
      if (immediateFocus) {
        inputRef.current?.focus();
      } else {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }
    }
  }, [autoFocus, focus, immediateFocus]);

  const containerRef = useRef<HTMLDivElement>(null);
  const onMouseDown = (ev: React.MouseEvent) => {
    if (ev.target === containerRef.current) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  };

  const onClearSearch = useEventCallback(() => {
    onChange('');
    inputRef.current?.focus();
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={containerRef}
      className={clsx(style.searchField, className)}
      onMouseDown={onMouseDown}
      style={{ minWidth }}
    >
      <SearchIcon className={style.icon} />
      <input
        ref={inputRef}
        aria-label="search"
        name="searchQuery"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e.target.value)}
        value={value}
        className={clsx(style.searchInput)}
        placeholder={placeholder}
        autoComplete="off"
        role="searchbox"
        onBlur={e => {
          onBlur();
          onBlurProp?.(e);
        }}
        {...props}
      />
      {value && (
        <IconButton
          icon={<ClearIcon className={style.icon} aria-hidden />}
          onClick={onClearSearch}
        />
      )}
    </div>
  );
};

export default SearchField;
