import React, { ReactNode } from 'react';
import { ExternalLinkTarget } from '../ExternalLink';
import Card from '../Card';
import Title from '../Title';
import { Feature, trackFeature } from '../../../../utils/features';
import style from './index.module.css';

type AdditionResourcesCardProps = {
  iconSrc: ReactNode;
  title: string;
  subTitle: string;
  buttonText: string;
  to?: string;
  href?: string;
  target?: ExternalLinkTarget;
  featureId: Feature;
};

export type ResourceType = {
  title: string;
  subTitle: string;
  buttonText: string;
  url: string | undefined;
  icon: React.ReactNode;
};

const ResourcesCard: React.FC<AdditionResourcesCardProps> = ({
  iconSrc,
  title,
  subTitle,
  buttonText,
  to,
  href,
  target,
  featureId,
}) => {
  return (
    <Card
      to={to}
      href={href}
      target={target}
      className={style.resourceCard}
      onClick={() => {
        trackFeature(featureId);
      }}
    >
      <div className={style.iconContainer}>{iconSrc}</div>
      <div className={style.content}>
        <Title className={style.title}>{title}</Title>
        <div className="subtitle">{subTitle}</div>
        <div className={style.button}>{buttonText}</div>
      </div>
    </Card>
  );
};

const Resources: React.FC<{ resources: Array<ResourceType> }> = ({ resources }) => {
  return (
    <div className={style.additionalResourcesContainer}>
      {resources.map(resource => (
        <ResourcesCard
          key={resource.title}
          iconSrc={resource.icon}
          title={resource.title}
          target={ExternalLinkTarget.newTab}
          subTitle={resource.subTitle}
          buttonText={resource.buttonText}
          href={resource.url}
          featureId={Feature.HomepageKnowledgeBase}
        />
      ))}
    </div>
  );
};

export default Resources;
