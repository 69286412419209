import React, { createContext, useContext } from 'react';
import { FCC } from '../../../../utils/types';

type PartnerContextInterface = {
  partnerAccountId: string;
};

export const PartnerContext = createContext<PartnerContextInterface>({ partnerAccountId: '' });

type PartnerContextProviderProps = {
  partnerAccountId: string;
};

const PartnerContextProvider: FCC<PartnerContextProviderProps> = ({
  children,
  partnerAccountId,
}) => {
  return <PartnerContext.Provider value={{ partnerAccountId }}>{children}</PartnerContext.Provider>;
};

export default PartnerContextProvider;

export const usePartnerContext = () => useContext(PartnerContext);
export const usePartnerAccountId = () => useContext(PartnerContext).partnerAccountId;
