import React, { SVGProps } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Tooltip, { Placement } from '../../components/ui/Tooltip';
import { MenuItem, useResponsive } from '../../components/ui';
import { Feature, invokesFeature, trackFeature } from '../../utils/features';
import { routeIdFromPath } from '../../components/lettuce/crisp/routing/lib';
import { RouteId } from '../../components/lettuce/crisp/routing/Routes';
import styles from './MainMenuItem.module.css';

export type MenuOption = {
  featureId?: Feature;
  href?: string;
  externalHref?: string;
  id: string;
  onClick?: () => void;
  subOptions?: MenuOption[];
  testId?: string;
  text: string;
  subText?: string;
  tooltip?: string;
  category?: string;
  disabled?: boolean;
  Icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
};

type MainMenuItemProps = Omit<MenuOption, 'id'> & {
  isSelectedRoute?: (routeId: RouteId) => boolean;
};

export default function MainMenuItem({
  featureId,
  onClick,
  href,
  externalHref,
  testId,
  text,
  tooltip,
  isSelectedRoute,
  disabled,
}: MainMenuItemProps) {
  const { isMaxSmallScreen } = useResponsive();

  const routeId = href ? routeIdFromPath(href) : undefined;
  const selected = routeId && isSelectedRoute?.(routeId);

  const shouldDisplayTooltip =
    (!useMediaQuery({
      maxWidth: 1020,
    }) ||
      isMaxSmallScreen) &&
    !!tooltip;

  // Prevent inconvenient overlap over menu item on narrow screens (mobile)
  const tooltipWidth = isMaxSmallScreen ? 180 : undefined;
  const tooltipContent = (
    <div className={styles.tooltipContent} style={{ width: tooltipWidth }}>
      {tooltip}
    </div>
  );

  // eslint-disable-next-line
  const renderContent = (ref?: React.Ref<any>) =>
    href && !disabled ? (
      <MenuItem selected={selected} disabled={disabled}>
        <Link
          data-testid={testId}
          onClick={() => {
            featureId && trackFeature(featureId);
            onClick && onClick();
          }}
          ref={ref}
          to={href}
          {...invokesFeature(featureId)}
        >
          {text}
        </Link>
      </MenuItem>
    ) : (
      <MenuItem
        data-testid={testId}
        onClick={() => {
          featureId && trackFeature(featureId);
          onClick && onClick();
        }}
        selected={selected}
        disabled={disabled}
        {...invokesFeature(featureId)}
      >
        {externalHref ? (
          <a href={externalHref} target="_blank" rel="noopener noreferrer">
            <div ref={ref}>{text}</div>
          </a>
        ) : (
          <div ref={ref}>{text}</div>
        )}
      </MenuItem>
    );

  return shouldDisplayTooltip ? (
    <Tooltip content={tooltipContent} placement={Placement.Right}>
      {ref => renderContent(ref)}
    </Tooltip>
  ) : (
    renderContent()
  );
}
