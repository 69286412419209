import React from 'react';
import clsx from 'clsx';
import styles from './index.module.css';

type Props = {
  children: React.ReactNode;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export const Tag = React.forwardRef(
  ({ className, children, ...spanProps }: Props, ref?: React.Ref<HTMLSpanElement>) => (
    <span ref={ref} className={clsx(styles.tag, className)} {...spanProps}>
      {children}
    </span>
  ),
);

Tag.displayName = 'Tag';
