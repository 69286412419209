import React, { ReactNode, Suspense, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import MaxWidthLayout from '../../components/layout/MaxWidthLayout';
import { useHelpUrl } from '../../crisp/navigation/HelpLinkContextProvider';
import { NotFound } from '../../../httpErrors';
import NavRailPageTemplate from '../../components/NavRailPageTemplate';
import NavRailMenu from '../navigation/NavRailMenu';
import { PartnerIdParams } from '../lib';
import RequirePartnerGrant, { RequirePartnerGrantProps } from '../lib/RequirePartnerGrant';
import PartnerContextProvider from '../lib/PartnerContext';
import { Theme } from '../../components/Theme';
import { useUserPreferencesContext } from '../../crisp/UserPreferences/UserPreferencesProvider';
import { useThemeContext } from '../../components/Theme/ThemeProvider';
import SegmentPartnerAccountTracker from './SegmentPartnerAccountTracker';

const usePartnerAccountTheme = () => {
  const history = useHistory();
  // Retrieve partner theme based on partner account settings (pass partnerId as param to this hook)
  const { preferences } = useUserPreferencesContext();
  const overrideTheme = preferences.general.overrideAccountTheme;
  const { setTheme: applyTheme } = useThemeContext();

  useLayoutEffect(() => {
    if (!overrideTheme) {
      applyTheme(Theme.Dot);
      const onChange = () => applyTheme(Theme.Dot);
      return history.listen(onChange);
    }
  }, [history, overrideTheme, applyTheme]);
};

type PartnerTemplateProps<T extends object> = {
  children?: ReactNode;
  helpUrl?: string;
  Layout?: 'div' | React.FC<T>;
} & Pick<RequirePartnerGrantProps, 'RenderForbidden'>;

function PartnerTemplate<T extends object>({
  children,
  RenderForbidden,
  Layout = MaxWidthLayout as React.FC<T>,
  helpUrl,
  ...props
}: PartnerTemplateProps<T>) {
  const { params } = useRouteMatch<PartnerIdParams>();
  const { partnerId } = params;
  useHelpUrl(helpUrl);
  usePartnerAccountTheme();

  if (!partnerId) {
    return <NotFound />;
  }

  const layoutProps = props as T;
  return (
    <NavRailPageTemplate<PartnerIdParams> RenderNavRail={NavRailMenu} partnerId={partnerId}>
      <RequirePartnerGrant partnerId={partnerId} RenderForbidden={RenderForbidden}>
        <main id="main">
          <PartnerContextProvider partnerAccountId={partnerId}>
            <Layout {...layoutProps}>
              <Suspense>{children}</Suspense>
            </Layout>
          </PartnerContextProvider>
        </main>
        <SegmentPartnerAccountTracker partnerId={partnerId} />
      </RequirePartnerGrant>
    </NavRailPageTemplate>
  );
}

export default PartnerTemplate;
