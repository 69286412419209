import React from 'react';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import {
  BaseMenuItem,
  Dropdown,
  DropdownStyle,
  Menu,
  MenuType,
  OverlayPosition,
  VerticalMenu,
} from '../../components/ui';
import MainMenuItem, { MenuOption } from './MainMenuItem';
import useIsSelectedRoute from './useIsSelectedRoute';
import commonStyles from '../../components/common.module.css';
import styles from './MainMenu.module.css';

export type { MenuOption } from './MainMenuItem';

type MainMenuProps = {
  isMenuOptionSelected: (menuOption: MenuOption, pathname: string) => boolean;
  menuType: MenuType;
  menuOptions: MenuOption[];
};

const categoryComparator = (a, b) => {
  // categories that are falsey will be at the bottom, without a header
  if (!a) {
    return 1;
  }
  if (!b) {
    return 1;
  }

  // "My bookmarks" category will be at the top in Norwegian
  if (a === 'Mine bokmerker') {
    return -1;
  }
  if (b === 'Mine bokmerker') {
    return 1;
  }

  return a.localeCompare(b);
};

function MenuCategories({ categories }: { categories: _.Dictionary<MenuOption[]> }) {
  const categoryNames = Object.keys(categories);

  return (
    <>
      {categoryNames
        .sort(categoryComparator) // TODO some nicer way to specify ordering of categories
        .map((categoryName, index) => (
          <VerticalMenu hoverEnabled key={categoryName}>
            {/* Do not display category header if falsey or 'undefined' */}
            {categoryName && categoryName !== 'undefined' ? (
              <div className={commonStyles.heading}>{categoryName}</div>
            ) : null}
            {categories[categoryName].map(s => {
              const menuItem = (
                <BaseMenuItem
                  key={`menuitem-${s.id}`}
                  onClick={s.onClick ?? (() => {})} // required to apply the hover effect
                  disabled={s.disabled}
                >
                  <div>
                    {s.text}
                    {s.subText ? <span className={styles.subText}>{s.subText}</span> : null}
                  </div>
                  {s.Icon ? <s.Icon className={styles.icon} /> : null}
                </BaseMenuItem>
              );

              return s.externalHref ? (
                <a href={s.externalHref} key={s.id} target="_blank" rel="noopener noreferrer">
                  {menuItem}
                </a>
              ) : s.href && !s.disabled ? (
                <Link to={s.href} key={s.id}>
                  {menuItem}
                </Link>
              ) : (
                menuItem
              );
            })}
            {index < categoryNames.length - 1 && <div className={commonStyles.separator} />}
          </VerticalMenu>
        ))}
    </>
  );
}

export default function MainMenu({ menuType, menuOptions, isMenuOptionSelected }: MainMenuProps) {
  const location = useLocation();
  const isSelectedRoute = useIsSelectedRoute();

  return (
    <Menu type={menuType}>
      {menuOptions.map(m => {
        const categories = _.groupBy(m.subOptions, 'category');
        const selectedSub = m.subOptions?.find(o => isMenuOptionSelected(o, location.pathname));
        return m.subOptions ? (
          <Dropdown
            className="mhm"
            key={m.id}
            overlayPosition={OverlayPosition.Right}
            overlay={<MenuCategories categories={categories} />}
            dropdownStyle={DropdownStyle.FLAT}
            featureId={m.featureId}
          >
            <span>{selectedSub ? selectedSub.text : m.text}</span>
          </Dropdown>
        ) : (
          <MainMenuItem key={m.id} {...m} isSelectedRoute={isSelectedRoute} />
        );
      })}
    </Menu>
  );
}

MainMenu.defaultProps = {
  isMenuOptionSelected: (menuOption: MenuOption, pathname: string) => menuOption.href === pathname,
};
