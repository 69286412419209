import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Button, ButtonStyle, Grid, GridType, Spinner, SpinnerSize } from '../ui';
import styles from './index.module.css'; // eslint-disable-line

type ListItemProps = {
  title?: ReactNode;
  children: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  actionsVerticalAlign?: EntityListItemActionsVerticalAlign;
  compact?: boolean;
  isPendingAction?: boolean;
};

export type ListItemAction = {
  label: string;
  disabled: boolean;
  fn: () => void;
};

export const deleteAction = (fn: () => void, disabled = false): ListItemAction => ({
  label: 'Delete',
  fn,
  disabled,
});

export enum EntityListItemActionsVerticalAlign {
  Bottom = 'Bottom',
  Center = 'Center',
  Top = 'Top',
}

export function EntityListItemWithActions({
  title,
  actions,
  onMouseEnter,
  onMouseLeave,
  children,
  actionsVerticalAlign = EntityListItemActionsVerticalAlign.Bottom,
  compact = false,
  isPendingAction = false,
}: ListItemProps & { actions: ListItemAction[] }) {
  const isOneLine = !children;
  return (
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={'entity-list-item'}
      className={clsx({
        [styles.entityListItemWithActionsOneLineLi]: isOneLine,
        [styles.compact]: compact,
      })}
    >
      {title && <div>{title}</div>}
      <Grid
        type={GridType.S}
        className={isOneLine ? styles.entityListItemWithActionsOneLineGrid : undefined}
      >
        {children ? <div style={{ flexGrow: 1 }}>{children}</div> : ''}
        {!!actions.length && (
          <div className={clsx(styles.actions, styles[`align${actionsVerticalAlign}`])}>
            {isPendingAction ? (
              <Spinner size={SpinnerSize.SMALL} />
            ) : (
              actions.map(({ label, disabled, fn }) => (
                <div key={label}>
                  <Button
                    onClick={fn}
                    disabled={disabled}
                    btnStyle={ButtonStyle.LINK}
                    className={styles.actionButton}
                  >
                    {label}
                  </Button>
                </div>
              ))
            )}
          </div>
        )}
      </Grid>
    </li>
  );
}

EntityListItemWithActions.defaultProps = {
  actions: [],
};

export type EntityListItemProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  editLabel: string;
  deleteLabel: string;
  actionsDisabled: boolean;
  editDisabled?: boolean;
} & ListItemProps;

export function EntityListItem({
  onEdit,
  onDelete,
  editLabel,
  deleteLabel,
  children,
  actionsDisabled,
  editDisabled,
  ...props
}: EntityListItemProps) {
  const actions = [
    [onEdit, editLabel, actionsDisabled || editDisabled],
    [onDelete, deleteLabel, actionsDisabled],
  ]
    .filter(([action]) => action)
    .map(([fn, label, isDisabled]) => ({ label, fn, disabled: isDisabled })) as ListItemAction[];

  return (
    <EntityListItemWithActions {...props} actions={actions}>
      {children}
    </EntityListItemWithActions>
  );
}

EntityListItem.defaultProps = {
  editLabel: 'Edit',
  deleteLabel: 'Delete',
  actionsDisabled: false,
};

type ListProps = {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  headingText?: string;
  id?: string;
};

export function EntityList({ children, className, style, headingText, id }: ListProps) {
  return (
    <div className={className}>
      {headingText ? <h2 className={styles.heading}>{headingText}</h2> : null}
      <ul className={styles.entityList} style={style} id={id}>
        {children}
      </ul>
    </div>
  );
}
