import React from 'react';
import clsx from 'clsx';
import { ReactComponent as IconClose } from '../../../../assets/icons/close-green.svg';
import { Button, ButtonStyle } from '../../Button';
import styles from './SelectedOption.module.css';

export type Option = {
  id: string;
  value: string;
};

const SelectedOption = ({
  option,
  onRemove,
  className,
}: {
  option: Option;
  onRemove?: (option: Option) => void;
  className?: string;
}) => (
  <li>
    <Button
      className={clsx(styles.selectedOptionButton, className, { [styles.disabled]: !onRemove })}
      btnStyle={ButtonStyle.LINK_DEFAULT_TEXT_COLOR}
      onClick={() => onRemove?.(option)}
      disabled={!onRemove}
    >
      <span className={styles.selectedOption}>{option.value}</span>
      {!!onRemove && <IconClose title={`Deselect ${option.value}`} height={10} width={10} />}
    </Button>
  </li>
);

export default SelectedOption;
