import React, { useEffect, useState } from 'react';
import { noop } from '@tellurian/ts-utils';
import { get, set } from 'idb-keyval';
import _ from 'lodash';
import useEventCallback from '../../utils/useEventCallback';
import { GeneralSettingsStore } from '../../common/IndexDBStores';
import { FCC } from '../../../../utils/types';
import logger from '../../../../services/logger';
import useKeyboardShortcut from '../../../keyboardSupport/useKeyboardShortcut';
import UserPreferencesEditor, { UserPreferencesShortcutSpec } from './UserPreferencesEditor';

export const ThemeOptions = [
  'Crisp Lettuce',
  'UNFI',
  'Rema',
  'Advantage',
  'High Impact',
  'Dot',
  'Instacart',
] as const;
export type ThemeOption = (typeof ThemeOptions)[number];

type UserPreferences = {
  general: {
    theme: ThemeOption;
    overrideAccountTheme: boolean;
  };
  labs: {
    powerBi: {
      ux2: boolean;
    };
  };
};

type PartialUserPreferences = Partial<{
  [K in keyof UserPreferences]: Partial<UserPreferences[K]>;
}>;

const DefaultPreferences: UserPreferences = {
  general: {
    theme: 'Crisp Lettuce',
    overrideAccountTheme: false,
  },
  labs: {
    powerBi: {
      ux2: false,
    },
  },
};

type UserPreferencesContextInterface = {
  preferences: UserPreferences;
  setPreferences: (preferences: UserPreferences) => void;
  updatePreferences: (preferences: PartialUserPreferences) => void;
  setTheme: (theme: ThemeOption) => void;
  toggleEditor: () => void;
};

const UserPreferencesContext = React.createContext<UserPreferencesContextInterface>({
  preferences: DefaultPreferences,
  setPreferences: noop,
  updatePreferences: noop,
  setTheme: noop,
  toggleEditor: noop,
});

const Versions = ['1'];
const getKey = () => `UserPreferences_v${Versions[0]}`;
const getPreferences = async (): Promise<UserPreferences> => {
  return get(getKey(), GeneralSettingsStore).then(preferences => {
    return _.merge({}, DefaultPreferences, preferences);
  });
};

const UserPreferencesContextProvider: FCC = ({ children }) => {
  const [isEditorActive, setIsEditorActive] = useState(false);
  const toggleEditor = useEventCallback(() => setIsEditorActive(!isEditorActive));
  useKeyboardShortcut(UserPreferencesShortcutSpec, toggleEditor);
  const [preferences, setPreferences] = useState(DefaultPreferences);
  useEffect(() => {
    getPreferences()
      .then(setPreferences)
      .catch(err => {
        logger.error('Failed to load user preferences', err);
      });
  }, []);

  const _setPreferences = (nextPreferences: UserPreferences) => {
    setPreferences(nextPreferences);
    set(getKey(), nextPreferences, GeneralSettingsStore).catch(err => {
      logger.error('Failed to save user preferences', err);
    });
  };

  const updatePreferences = (nextPreferences: PartialUserPreferences) => {
    _setPreferences(_.merge({}, preferences, nextPreferences));
  };

  const setTheme = useEventCallback((theme: ThemeOption) => {
    updatePreferences({ general: { theme } });
  });

  return (
    <UserPreferencesContext.Provider
      value={{
        preferences,
        setPreferences: _setPreferences,
        updatePreferences,
        setTheme,
        toggleEditor,
      }}
    >
      <UserPreferencesEditor onClose={() => setIsEditorActive(false)} isActive={isEditorActive} />
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesContextProvider;

export const useUserPreferencesContext = () => React.useContext(UserPreferencesContext);
