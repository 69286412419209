import React, { ReactElement, useMemo } from 'react';
import { UseListOfItemsMultiSelect } from './useListOfItemsMultiSelect';
import { SelectableListHooks } from './lib';
import ListOfItems, { ListOfItemsProps } from './ListOfItems';
import SelectableListItem, { RenderSelectableItemProps } from './SelectableListItem';
import ListItem from './listItems/ListItem';

type ListOfItemsSelectProps<T> = Pick<
  UseListOfItemsMultiSelect<T>,
  | 'useSelectableItem'
  | 'useActionableItem'
  | 'useFocusableItem'
  | 'items'
  | 'focusTransitionsEnabled'
> & {
  getFocusParentProps?: UseListOfItemsMultiSelect<T>['getFocusParentProps'];
  RenderItem: React.FC<RenderSelectableItemProps<T>>;
  RenderListItem?: typeof ListItem;
  extraItemProps?: object;
} & Pick<
    ListOfItemsProps<T>,
    // Add to list as necessary
    | 'RenderAfterItem'
    | 'RenderBeforeItem'
    | 'getItemId'
    | 'getItemKey'
    | 'focus'
    | 'id'
    | 'tabIndex'
    | 'className'
  >;

const ListOfItemsSelect = React.memo(function ListOfItemsSelect<T>({
  getFocusParentProps,
  RenderItem,
  RenderListItem = ListItem,
  ...rest
}: ListOfItemsSelectProps<T>) {
  type ItemProps = SelectableListHooks<T> & { focusTransitionsEnabled: boolean };
  return (
    <ListOfItems<T, ItemProps>
      {...getFocusParentProps?.()}
      {...rest}
      RenderItem={useMemo(
        () => SelectableListItem(RenderItem, RenderListItem),
        [RenderItem, RenderListItem],
      )}
    />
  );
}) as <T>(props: ListOfItemsSelectProps<T>) => ReactElement;

export default ListOfItemsSelect;
