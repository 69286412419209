import React, { ReactElement } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { defaultGetSizeProps, DefaultPopoverMaxHeight } from '../useDropdown';
import FloatingPortal from '../FloatingPortal';
import Popover from '../Popover';
import InputSelect, { InputSelectProps } from '../Input/InputSelect';
import useDropdownListOfItemsActionable, {
  UseDropdownListOfItemsSelectParams,
} from '../useDropdownListOfItemsSelect';
import ListOfItems, { ListOfItemsProps } from '../../../../ui/lists/listOfItems/ListOfItems';
import { ActionableListProps } from '../../../../ui/lists/listOfItems/lib';
import { useDefaultHashId } from '../../../../lib';
import Backdrop from '../../Backdrop';
import { GetSizeProps } from '../useTether';
import LoadingListItems from '../LoadingListItems';
import style from '../../ChipDropdown/ChipDropdownSelect.module.css';

const ListStyle = Object.freeze({
  maxHeight: DefaultPopoverMaxHeight,
});

const getPopoverSizeProps: GetSizeProps = params => {
  const { rects } = params;
  return {
    ...defaultGetSizeProps(params),
    minHeight: 0,
    maxWidth: Math.max(rects.reference.width, 200),
  };
};

type SelectDropdownComponent = <T>(
  props: SelectDropdownProps<T> & { ref: React.Ref<HTMLInputElement> },
) => ReactElement;

/**
 * A generic dropdown component with an InputSelect as trigger.
 * Use with list items of a custom type (e.g. with list item containing an icon and various pieces of information, toggles etc)
 * or as a base component for more specific types (e.g. list with numbers or name/value pairs)
 */
export type SelectDropdownProps<T> = UseDropdownListOfItemsSelectParams<T> & {
  getInputText: (selectedItem: Maybe<T>) => string;
  listId?: string;
  backdrop?: boolean;
  isLoadingItems?: boolean;
} & Pick<InputSelectProps, 'label' | 'hasError' | 'disabled' | 'onBlur'> &
  Pick<ListOfItemsProps<T, ActionableListProps>, 'getItemKey' | 'RenderItem'>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EnabledSelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps<any>>(
  function SelectDropdown<T>(
    {
      selectedItem,
      label,
      getInputText,
      getItemKey,
      listId,
      RenderItem,
      backdrop = false,
      role = 'listbox',
      hasError,
      isLoadingItems = false,
      ...dropdownProps
    }: SelectDropdownProps<T>,
    ref,
  ) {
    listId = useDefaultHashId(listId);
    const { getReferenceProps, getPopoverProps, listProps, isActive } =
      useDropdownListOfItemsActionable<T, HTMLInputElement>({
        getPopoverSizeProps,
        ...dropdownProps,
        selectedItem,
        role,
        stopKeyDownPropagation: true,
        toggleOnSpace: true,
      });
    const className = isActive && backdrop ? 'overBackdrop' : undefined;
    const referenceProps = getReferenceProps('toggle');

    return (
      <>
        {isActive && backdrop && <Backdrop variant="transparent" />}
        <InputSelect
          {...referenceProps}
          value={getInputText(selectedItem)}
          label={label}
          className={className}
          hasError={hasError}
          containerRef={referenceProps.ref}
          ref={ref}
        />
        {isActive && (
          <FloatingPortal>
            <Popover className={className} {...getPopoverProps()}>
              {isLoadingItems ? (
                <LoadingListItems />
              ) : (
                <ListOfItems<T, ActionableListProps>
                  {...listProps}
                  RenderItem={RenderItem}
                  className={style.list}
                  id={listId}
                  getItemKey={getItemKey}
                  style={ListStyle}
                  role="none"
                />
              )}
            </Popover>
          </FloatingPortal>
        )}
      </>
    );
  },
) as SelectDropdownComponent;

const DisabledSelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps<unknown>>(
  function SelectDropdown<T>(
    { selectedItem, label, getInputText, hasError }: SelectDropdownProps<T>,
    ref,
  ) {
    return (
      <InputSelect
        value={getInputText(selectedItem)}
        label={label}
        hasError={hasError}
        disabled={true}
        ref={ref}
      />
    );
  },
) as SelectDropdownComponent;

const SelectDropdown = React.forwardRef<HTMLInputElement, SelectDropdownProps<unknown>>(
  function SelectDropdown<T>(props, ref) {
    return props.disabled ? (
      <DisabledSelectDropdown<T> {...props} ref={ref} />
    ) : (
      <EnabledSelectDropdown<T> {...props} ref={ref} />
    );
  },
);

export default SelectDropdown as SelectDropdownComponent;
