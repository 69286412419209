import {
  ConnectorApplication,
  useGetConnectorConfigurationsForHomepageQuery,
} from '../../generated/graphql';
import { useAuthorizationContext } from '../security/AuthorizationContext';
import { path } from '../lettuce/crisp/routing/lib';

type UseGetMdmPathArgs = {
  accountId?: string;
};

const useGetMdmPath = ({ accountId }: UseGetMdmPathArgs) => {
  const { getHasAccountAdminPermission } = useAuthorizationContext();
  const isAccountAdmin = accountId ? getHasAccountAdminPermission(accountId) : false;

  const { data: connectorConfigsData, loading } = useGetConnectorConfigurationsForHomepageQuery({
    variables: { accountId: accountId ?? '' },
    skip: !accountId || !isAccountAdmin,
    fetchPolicy: 'cache-and-network',
  });

  const mdmConnectorConfig = connectorConfigsData?.account.connectorConfigurations.edges.find(
    e => e.node.connector.application === ConnectorApplication.GoogleSheetsMasterData,
  )?.node;
  const mdmConnectorId = connectorConfigsData?.account.connectors.find(
    c => c.application === ConnectorApplication.GoogleSheetsMasterData,
  )?.id;

  return {
    mdmPath: mdmConnectorConfig
      ? path('EditConnectorConfiguration')({
          accountId,
          connectorConfigurationSlug: mdmConnectorConfig.slug,
        })
      : mdmConnectorId
        ? path('AddConnectorConfiguration')({
            accountId,
            connectorId: mdmConnectorId,
          })
        : null,
    loading,
  };
};

export default useGetMdmPath;
