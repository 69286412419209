import React from 'react';
import style from './MenuItem.module.css'; // eslint-disable-line
import { MenuItem, MenuStateReturn } from 'reakit/Menu';
import RenderKeyCombo, { KeyComboSize } from '../keyboardSupport/RenderKeyCombo';
import { KeyboardShortcut, KeyCombo } from '../keyboardSupport/lib';
import useKeyboardShortcut from '../keyboardSupport/useKeyboardShortcut';

type ActionMenuItemBodyProps = {
  label: string;
  icon?: React.ReactNode;
  keyCombo?: KeyCombo;
};

const ActionMenuItemBody: React.FC<ActionMenuItemBodyProps> = ({ label, icon, keyCombo }) => {
  return (
    <>
      <div className={style.menuItemBody}>
        <div className={style.iconAndTextContainer}>
          {icon}
          {label}
        </div>
        {keyCombo && (
          <div>
            <RenderKeyCombo keyCombo={keyCombo} size={KeyComboSize.S} />
          </div>
        )}
      </div>
    </>
  );
};

type ActionMenuItemProps = {
  menu: MenuStateReturn;
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
  keyCombo?: KeyCombo;
};

export const ActionMenuItem: React.FC<ActionMenuItemProps> = ({
  menu,
  label,
  icon,
  keyCombo,
  ...rest
}) => {
  return (
    <MenuItem
      {...menu}
      className={style.navigationMenuItem}
      as="button"
      aria-label={label}
      {...rest}
    >
      <ActionMenuItemBody label={label} icon={icon} keyCombo={keyCombo} />
    </MenuItem>
  );
};

type ActionMenuItemWithShortcutProps = Omit<
  ActionMenuItemProps,
  'label' | 'keyCombo' | 'onClick'
> & {
  // Use keyboardShortcut description by default but allow label overrides
  label?: string;
  keyboardShortcut: KeyboardShortcut;
  registerShortcut?: boolean;
};

const ActionMenuItemRegisterShortcut: React.FC<
  Omit<ActionMenuItemWithShortcutProps, 'registerShortcut'>
> = ({ keyboardShortcut, ...rest }) => {
  const { fn, ...spec } = keyboardShortcut;
  useKeyboardShortcut(spec, fn);
  return (
    <ActionMenuItem
      {...rest}
      label={rest.label ?? spec.description}
      onClick={() => {
        rest.menu.setVisible(false);
        fn();
      }}
    />
  );
};

export const ActionMenuItemWithShortcut: React.FC<ActionMenuItemWithShortcutProps> = ({
  registerShortcut = true,
  ...props
}) => {
  if (registerShortcut) {
    return <ActionMenuItemRegisterShortcut {...props} />;
  }
  const {
    keyboardShortcut: { keyCombo, fn, description },
    label,
    ...rest
  } = props;
  return <ActionMenuItem {...rest} keyCombo={keyCombo} onClick={fn} label={label ?? description} />;
};
