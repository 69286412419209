import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routeIdFromPath } from '../../components/lettuce/crisp/routing/lib';
import { RouteId } from '../../components/lettuce/crisp/routing/Routes';

export default function useIsSelectedRoute(): (routeId: RouteId) => boolean {
  const location = useLocation();
  const currentRouteId = useMemo(() => routeIdFromPath(location.pathname), [location.pathname]);
  return useCallback((routeId: RouteId) => routeId === currentRouteId, [currentRouteId]);
}
