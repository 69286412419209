import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as CrispLogo } from '../../../../assets/clogo.svg';
import { ReactComponent as AdvantageLogo } from '../../../../assets/advantage-logo.svg';
import { ReactComponent as InstacartLogo } from '../../../../assets/instacart.svg';
import HighImpactLogo from '../../../../assets/highImpact-logo.png';
import { useIsCompactNavRail } from '../../components/NavRail/NavRailContextProvider';
import { path } from '../routing/lib';
import { useThemeContext } from '../../components/Theme/ThemeProvider';
import { Theme } from '../../components/Theme';
import style from './LogoLink.module.css';

const LogoStyles: Partial<Record<Theme, object>> = {
  [Theme.Advantage]: {
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '36px',
    height: '36px',
  },
  [Theme.HighImpact]: {
    width: '24px',
  },
};

const LogoComponents: Partial<Record<Theme, typeof CrispLogo>> = {
  [Theme.Advantage]: AdvantageLogo,
  [Theme.Instacart]: InstacartLogo,
};

const getLogoSvgComponent = (theme: Theme) => {
  return LogoComponents[theme] || CrispLogo;
};

const Logo: React.FC = () => {
  const { theme } = useThemeContext();
  if (theme === Theme.HighImpact) {
    return <img src={HighImpactLogo} alt="logo" style={LogoStyles[Theme.HighImpact]} />;
  }
  const LogoSVG = getLogoSvgComponent(theme);
  return <LogoSVG style={LogoStyles[theme]} />;
};

const LogoLink: React.FC = () => {
  return (
    <Link
      to={path('Home')()}
      className={clsx(style.logoContainer, { [style.compact]: useIsCompactNavRail() })}
    >
      <Logo />
    </Link>
  );
};

export default LogoLink;
