import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MainContent } from '../components/ui';
import PageError from '../components/errorHandling/PageError';
import PageMessage from '../components/PageMessage';
import { RequireGlobalAdmin } from '../components/security/RequireGlobalAdmin';
import NavBar from './NavBar';
import { MenuOption } from './NavBar/MainMenu';
import PageContainer, { PageContainerProps } from './PageContainer';

export type PageTemplateProps = {
  children: React.ReactNode;
  menuOptions?: MenuOption[];
  requireGlobalAdmin?: boolean;
} & PageContainerProps;

export default function PageTemplate({
  children,
  menuOptions,
  requireGlobalAdmin = false,
  includeBottomPadding,
}: PageTemplateProps) {
  const match = useRouteMatch<{ accountId?: string }>();
  const { accountId } = match.params;

  return (
    <PageContainer includeBottomPadding={includeBottomPadding}>
      <NavBar menuOptions={menuOptions} accountId={accountId} />
      <main id="main">
        <MainContent>
          <PageError type="page" hideIfTypePresent="modal" />
        </MainContent>
        <RequireGlobalAdmin requireGlobalAdmin={requireGlobalAdmin}>
          <MainContent>
            <PageMessage />
          </MainContent>
          <MainContent>
            <div style={{ minHeight: 400 }}>{children}</div>
          </MainContent>
        </RequireGlobalAdmin>
      </main>
    </PageContainer>
  );
}

PageTemplate.defaultProps = {};
