import { RouteId } from './lettuce/crisp/routing/Routes';

// Defines what to search per route when linking to the knowledge base or in the zendesk widget

export const HelpRegistry: Readonly<Partial<Record<RouteId, string>>> = {
  ResetPassword: 'reset password',
  AddConnectorConfigurationList: 'connectors',
  AddConnectorConfiguration: 'connectors',
  EditConnectorConfiguration: 'connectors',
  Connectors: 'connectors',
  ViewInvitation: 'invite user',
  ManageAccountMembers: 'manage users',
  BusinessDashboardGroup: 'dashboard',
  ManagePowerBiBookmarks: 'powerbi',
  Notifications: 'notifications',
  CreateNotification: 'notifications',
  EditNotification: 'notifications',
  DataCatalog: 'model documentation',
  AccountDataCatalog: 'model documentation',
  ManageSourceLabels: 'model documentation',
};
