import { useMemo, useCallback } from 'react';
import {
  DashboardGroup,
  DashboardGroupForPermissionsFragment,
  DashboardGroupFragment,
  useGetDashboardGroupsForPermissionsQuery,
  useGetDashboardGroupsQuery,
  useGetUnfiInsightsDashboardGroupsQuery,
} from '../../../generated/graphql';
import { useAuthenticationContext } from '../../security/AuthenticationContext';
import { useUnfiInsightsOnPlatformAuthenticationContext } from '../../lettuce/unfiInsightsOnPlatform/UnfiInsightsOnPlatformAuthenticationProvider';
import { isDashboardGroupDisabledByConnectorConfiguration } from '../lib';
import { useDashboardFilterForDashboardGroup } from './useDashboardFilterForDashboardGroup';
import { useFilterAmazonDashboardGroups } from './useFilterAmazonDashboardGroups';

const filterEnabledConnectorConfigurations = group => ({
  ...group,
  dashboards: group.dashboards.map(dashboard => ({
    ...dashboard,
    relatedConnectorConfigurations: dashboard.relatedConnectorConfigurations?.filter(
      c => c.enabled,
    ),
  })),
});

const useFilteredDashboardGroups = (
  dashboardGroups: Array<DashboardGroupFragment | DashboardGroupForPermissionsFragment>,
) => {
  const featureFlagFilter = useDashboardFilterForDashboardGroup();
  const filterAmazonDashboardGroups = useFilterAmazonDashboardGroups();

  return useMemo(() => {
    return dashboardGroups
      .filter(g => !isDashboardGroupDisabledByConnectorConfiguration(g))
      .filter(filterAmazonDashboardGroups)
      .map(featureFlagFilter)
      .map(filterEnabledConnectorConfigurations)
      .filter(g => g.dashboards.length > 0);
  }, [dashboardGroups, featureFlagFilter, filterAmazonDashboardGroups]);
};

export function useGetDashboardGroups(accountId?: string) {
  // Skip queries if the user is not yet logged in
  const { firebaseUser, loading: firebaseUserLoading } = useAuthenticationContext();
  const {
    loading: dashboardsLoading,
    data,
    refetch: refetchDashboardGroups,
  } = useGetDashboardGroupsQuery({
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: { accountId: accountId || '' },
    skip: !accountId || !firebaseUser || firebaseUserLoading,
    context: {
      // The graphql errors here should be ignored. Errors should be returned and surfaced in the
      // analytics menu where the content of this request is presented.
      shouldIgnoreGraphQlError: () => true,
    },
  });

  const { accessToken, isSignedInWithUnfiInsights } =
    useUnfiInsightsOnPlatformAuthenticationContext();

  const skipUnfi = useMemo(
    () => !accessToken || !firebaseUser || firebaseUserLoading || !isSignedInWithUnfiInsights,
    [accessToken, firebaseUser, firebaseUserLoading, isSignedInWithUnfiInsights],
  );

  const {
    data: unfiInsightsData,
    loading: unfiInsightsDashboardsLoading,
    refetch: refetchUnfiInsightsDashboardGroups,
  } = useGetUnfiInsightsDashboardGroupsQuery({
    variables: {
      accessToken: accessToken ?? '',
    },
    skip: skipUnfi,
  });

  const loading =
    firebaseUserLoading ||
    (!data && dashboardsLoading) ||
    (!unfiInsightsData && unfiInsightsDashboardsLoading);

  const refetch = useCallback(() => {
    if (skipUnfi) {
      return refetchDashboardGroups();
    }

    return Promise.all([refetchDashboardGroups(), refetchUnfiInsightsDashboardGroups()]);
  }, [skipUnfi, refetchDashboardGroups, refetchUnfiInsightsDashboardGroups]);

  const unfiInsightsDashboardGroups = useMemo(
    () =>
      /* Not all fields are relevant to UNFI Insights dashboards since there is
       * no inbound connector. Irrelevant fields are hard-coded here.
       */
      (unfiInsightsData?.unfiInsightsDashboardGroups ?? []).map(
        g =>
          ({
            ...g,
            dashboards: g.dashboards.map(d => ({
              ...d,
              state: {
                dataReady: true,
                hasInactiveConnector: false,
                isDisabledByConnectorConfiguration: false,
                isInEnabledTier: true,
              },
              isAssemblySupplier: false,
              isCrossConnector: false,
              relatedConnectorApplications: [],
            })),
            state: { isDisabledByConnectorConfiguration: false },
          }) as DashboardGroup,
      ),
    [unfiInsightsData?.unfiInsightsDashboardGroups],
  );

  const combinedDashboardGroups = useMemo(
    () => [...unfiInsightsDashboardGroups, ...(data?.account.dashboardGroups ?? [])],
    [unfiInsightsDashboardGroups, data?.account.dashboardGroups],
  );

  const filteredDashboardGroups = useFilteredDashboardGroups(combinedDashboardGroups);

  return {
    loading,
    dashboardGroups: filteredDashboardGroups as DashboardGroupFragment[],
    businessDashboardsEnabled: data?.account.businessDashboardsEnabled,
    refetch,
  };
}

export function useGetDashboardGroupsForPermissions(accountId: string) {
  const { loading, data, refetch } = useGetDashboardGroupsForPermissionsQuery({
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: { accountId: accountId },
  });

  const filteredDashboardGroups = useFilteredDashboardGroups(data?.account.dashboardGroups ?? []);

  return {
    loading: loading,
    dashboardGroups: filteredDashboardGroups as DashboardGroupForPermissionsFragment[],
    businessDashboardsEnabled: data?.account.businessDashboardsEnabled,
    refetch,
  };
}

export function useGetDashboardById(accountId: string) {
  const { dashboardGroups } = useGetDashboardGroups(accountId);
  return useCallback(
    ({ dashboardGroupId, dashboardId }: { dashboardGroupId: string; dashboardId: string }) =>
      dashboardGroups
        ?.find(g => g.id === dashboardGroupId)
        ?.dashboards.find(d => d.id === dashboardId),
    [dashboardGroups],
  );
}
