import React, { useCallback } from 'react';
import AccountAutoSelector from '../../../AccountAutoSelector';
import PageTemplate from '../../../../pageTemplates';
import { path } from '../routing/lib';
import { lazyWithRetry } from '../../../lib';

const RenderAccountAutoSelector = lazyWithRetry<typeof AccountAutoSelector>(
  () => import(/* webpackChunkName: "accountAutoSelector" */ '../../../AccountAutoSelector'),
);

const DataCatalogPage: React.FC = () => {
  const getDataCatalogUri = useCallback(
    (accountId: string) => path('AccountDataCatalog')({ accountId }),
    [],
  );

  return (
    <PageTemplate>
      <RenderAccountAutoSelector
        getAccountUri={getDataCatalogUri}
        heading="Data Catalog"
        description="Please select an account to view the data catalog"
      />
    </PageTemplate>
  );
};

export default DataCatalogPage;
