import React, { useEffect } from 'react';
import { PartnerIdParams } from '../lib';
import { Feature, trackFeature } from '../../../../utils/features';

type SegmentPartnerAccountTrackerProps = PartnerIdParams;

const SegmentPartnerAccountTracker: React.FC<SegmentPartnerAccountTrackerProps> = ({
  partnerId,
}) => {
  useEffect(() => {
    // TODO include customerId as extracted from the JWT when this is made available.
    trackFeature(Feature.PartnerAccountAccess, { partnerId });
  }, [partnerId]);

  return null;
};

export default SegmentPartnerAccountTracker;
