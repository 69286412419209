import useEventCallback from '../../../lettuce/utils/useEventCallback';
import useListItemMultiSelection, {
  UseListItemMultiSelection,
  UseListItemMultiSelectionParams,
} from './core/useListItemMultiSelection';
import { SelectableListHooks } from './lib';
import useListOfItemsActionable, {
  UseListOfItemsActionable,
  UseListOfItemsActionableParams,
} from './useListOfItemsActionable';

type OnItemToggleParams<T> = Pick<
  UseListItemMultiSelection<T>,
  'getSelection' | 'setSelection' | 'toggleItemSelected'
> & { item: T; index: number };

export type OnItemToggleFn<T> = (params: OnItemToggleParams<T>) => void;

export type UseListOfItemsMultiSelectParams<T, K = T> = Omit<
  UseListOfItemsActionableParams<T>,
  'onItemToggle'
> & {
  onSelectionChange?: (nextSelection: T[]) => void;
  selectAllEnabled?: boolean;
  onItemToggle?: OnItemToggleFn<T>;
} & Pick<UseListItemMultiSelectionParams<T, K>, 'getItemKey' | 'initialSelection'>;

export type UseListOfItemsMultiSelect<T> = SelectableListHooks<T> &
  Pick<UseListItemMultiSelection<T>, 'setSelection' | 'getSelection'> &
  UseListOfItemsActionable<T>;

const useListOfItemsMultiSelect = <T, K = T>({
  initialSelection = [],
  onSelectionChange,
  getItemKey,
  items,
  onItemToggle: onItemToggleProp = ({ item, toggleItemSelected }: OnItemToggleParams<T>) =>
    toggleItemSelected(item),
  ...actionableProps
}: UseListOfItemsMultiSelectParams<T, K>): UseListOfItemsMultiSelect<T> => {
  const { useSelectableItem, setSelection, toggleItemSelected, getSelection } =
    useListItemMultiSelection<T, K>({
      initialSelection,
      onChange: onSelectionChange,
      getItemKey,
    });

  const onItemToggle = useEventCallback((item: T, index: number) => {
    onItemToggleProp({ item, index, toggleItemSelected, getSelection, setSelection });
  });

  const actionable = useListOfItemsActionable({
    ...actionableProps,
    items,
    onItemToggle,
  });

  return {
    ...actionable,
    items,
    useSelectableItem,
    getSelection,
    setSelection,
  };
};

export default useListOfItemsMultiSelect;
