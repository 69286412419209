import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

const COOKIE_KEY = 'unfi_insights_access_token';

export const useUnfiInsightsAccessToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY]);

  const setAccessToken = useCallback(
    (token: string) => {
      setCookie(COOKIE_KEY, token, { path: '/', sameSite: 'none', secure: true });
    },
    [setCookie],
  );

  const clearAccessToken = useCallback(() => {
    removeCookie(COOKIE_KEY);
  }, [removeCookie]);

  return {
    accessToken: cookies[COOKIE_KEY],
    setAccessToken,
    clearAccessToken,
  };
};
