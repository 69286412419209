/*
DEPRECATED.
The following components will soon be replaced with components from core-ui/src/components/ui/form.
If you require a new component that is based on an input element, consider the TextFormField and TextField
examples defined in the aforementioned folder.
If your use case requires a component defined here, which does not have a corespondent in ui/form,
then it is fine to use it as is.
 */

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Maybe } from '@tellurian/ts-utils';
import { FieldError } from 'react-hook-form';
import { OptionalClassName } from '../lib';
import { FCC } from '../../../utils/types';
import { Input, InputProps } from './';
import styles from './FormComponents.module.css';

/**
 * Props for form control components, wrapping `Inputs` and adding labels and error messages.
 */
type FormFieldProps = {
  id?: string;
  description?: string;
  errorDelay?: number;
  label?: string;
  errorMessage?: string | FieldError;
  width?: number;
  hidden?: boolean;
};

type FormFieldContainerProps = {
  children: React.ReactNode;
};

export type FormFieldErrorType = Maybe<string | FieldError>;

/** DEPRECATED */
export const FormFieldError: FCC<{
  error: FormFieldErrorType;
}> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div className={styles.errorMessage}>{typeof error === 'string' ? error : error.message}</div>
  );
};

/** DEPRECATED */
export function FormField({
  id,
  label,
  description,
  errorDelay,
  errorMessage,
  children,
  width,
  className,
  hidden = false,
}: FormFieldProps & FormFieldContainerProps & OptionalClassName) {
  const [showErrorMessage, setShowErrorMessage] = useState(errorDelay === -1);
  useEffect(() => {
    if (errorDelay && errorMessage) {
      const timeout = setTimeout(() => {
        setShowErrorMessage(true);
      }, errorDelay);

      return () => clearTimeout(timeout);
    }
  }, [errorDelay, errorMessage]);

  return (
    <div className={clsx({ [styles.hidden]: hidden })}>
      {label || description ? <Label htmlFor={id} description={description} label={label} /> : null}
      <div className={clsx('mod', className)} style={{ width: width }}>
        {children}
        {errorMessage && showErrorMessage ? <FormFieldError error={errorMessage} /> : null}
      </div>
    </div>
  );
}

FormField.defaultProps = {
  errorDelay: -1,
};

/** DEPRECATED */
export function TextInput({
  id,
  label,
  description,
  errorDelay,
  errorMessage,
  hasError,
  testId,
  ...rest
}: InputProps<string> & FormFieldProps) {
  const idToUse = id || (label ? label.substring(0, 10) : undefined);
  return (
    <FormField
      {...rest}
      label={label}
      description={description}
      id={idToUse}
      errorDelay={errorDelay}
      errorMessage={errorMessage}
    >
      <Input {...rest} id={idToUse} hasError={hasError || !!errorMessage} testId={testId} />
    </FormField>
  );
}

TextInput.defaultProps = {
  type: 'text',
};

const getInputId = (id, label) => id || (label ? label.substring(0, 10) : '');

const getIntValue = (value: string | undefined): number | undefined => {
  if (!value) {
    return undefined;
  }

  if (/^\d+$/.test(value)) {
    return Number(value);
  }

  return NaN;
};

export type MaybePositiveInteger = number | undefined;
export type OnChangePositiveInteger = (value: MaybePositiveInteger) => void;
export type PositiveIntegerInputProps = {
  min?: number;
  onChange: OnChangePositiveInteger;
  value: MaybePositiveInteger;
};

/** DEPRECATED */
export function PositiveIntegerInput({
  description,
  errorMessage,
  hasError,
  id,
  label,
  min,
  onChange,
  value,
  formFieldClassName,
  ...rest
}: Omit<InputProps<string>, 'onChange' | 'value'> &
  FormFieldProps &
  PositiveIntegerInputProps & { formFieldClassName?: string }) {
  const idToUse = getInputId(id, label);
  const lastValueReported = useRef<number | undefined>(value);

  const onInputChange = (value: string | undefined) => {
    const n = getIntValue(value);
    if (n !== undefined && (Number.isNaN(n) || (min && n < min))) {
      return false;
    }

    if (lastValueReported.current !== n) {
      lastValueReported.current = n;
      onChange(n);
      return true;
    }

    return false;
  };

  return (
    <FormField
      {...rest}
      label={label}
      description={description}
      id={idToUse}
      errorMessage={errorMessage}
      className={formFieldClassName}
    >
      <Input
        {...rest}
        value={value === undefined ? '' : String(value)}
        onChange={onInputChange}
        id={idToUse}
        hasError={hasError || !!errorMessage}
        type="text"
      />
    </FormField>
  );
}

/** DEPRECATED */
export function IntegerInput({
  description,
  errorMessage,
  hasError,
  id,
  label,
  ...rest
}: InputProps<string> & FormFieldProps) {
  const idToUse = id || (label ? label.substring(0, 10) : '');

  return (
    <FormField
      {...rest}
      label={label}
      description={description}
      id={idToUse}
      errorMessage={errorMessage}
    >
      <Input {...rest} id={idToUse} hasError={hasError || !!errorMessage} type="number" />
    </FormField>
  );
}
IntegerInput.defaultProps = {
  type: 'text',
};

/** DEPRECATED */
export function FloatInput({
  description,
  errorMessage,
  focus,
  hasError,
  id,
  label,
  onChange,
  value,
  ...rest
}: InputProps<number | undefined> & FormFieldProps) {
  const idToUse = id || (label ? label.substring(0, 10) : '');
  const [displayValue, setDisplayValue] = useState<string | undefined>(
    value === undefined ? '' : String(value),
  );
  const lastValueReported = useRef<number | undefined>(value);

  function notifyChange(textValue: string, maybeNumber: number | undefined) {
    setDisplayValue(textValue.trim());
    if (lastValueReported.current !== maybeNumber) {
      lastValueReported.current = maybeNumber;
      onChange && onChange(maybeNumber);
    }
  }

  useEffect(() => {
    setDisplayValue(value === undefined ? '' : String(value));
  }, [value]);

  return (
    <FormField
      label={label}
      description={description}
      id={idToUse}
      errorMessage={errorMessage}
      {...rest}
    >
      <Input
        id={idToUse}
        type="number"
        value={displayValue}
        hasError={hasError}
        onChange={textValue => {
          if (textValue === '' || textValue === undefined) {
            return notifyChange(textValue, undefined);
          } else {
            const number = Number(textValue);
            if (!isNaN(number)) {
              return notifyChange(textValue, number);
            }
          }
        }}
        focus={focus}
        {...rest}
      />
    </FormField>
  );
}

FloatInput.defaultProps = {
  type: 'text',
};

/** DEPRECATED */
export function TextArea({
  description,
  label,
  onChange,
  rows,
  value,
}: { rows?: number } & InputProps<string> & FormFieldProps) {
  const id = label ? label.substring(0, 10) : '';
  return (
    <div>
      {label || description ? <Label htmlFor={id} description={description} label={label} /> : null}
      <div className="mod">
        <textarea
          onChange={e => onChange && onChange(e.target.value)}
          rows={rows}
          value={value || ''}
        />
      </div>
    </div>
  );
}

type LabelProps = {
  className?: string;
  htmlFor?: string;
  description?: string;
  label?: string;
};

export function Label({ className, description, htmlFor, label }: LabelProps) {
  return (
    <div className={clsx('mod', className)}>
      {label ? (
        <label className={styles.label} htmlFor={htmlFor}>
          {label}
        </label>
      ) : null}
      {description ? <div className={styles.labelDescription}>{description}</div> : null}
    </div>
  );
}

export const Forms = {
  Input: TextInput,
  IntegerInput: IntegerInput,
  FloatInput: FloatInput,
};
