import React from 'react';
import RouteSwitch from '../../components/routing/RouteSwitch';
import { RouteOptions } from '../../components/routing/lib';
import { Routes } from './Routes';

// Require authentication by default for all routes. Route declarations have to explicitly set
// requireAuth to false in order to bypass auth checks.
const DefaultRouteOptions: RouteOptions = { requireAuth: true };

const AppRouteSwitch: React.FC = () => (
  <RouteSwitch routes={Routes} defaultRouteOptions={DefaultRouteOptions} />
);

export default AppRouteSwitch;
