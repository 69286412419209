import { sha256 } from 'js-sha256';
import { AuthError as AzureAuthError } from '@azure/msal-common/dist/error/AuthError';
import { AuthError as FirebaseAuthError } from '@firebase/auth';
import { FirebaseError } from '@firebase/util';
import firebase from 'firebase/compat';
import { AccountInfo } from '@azure/msal-browser';

const NONCE_LENGTH = 15;

/**
 * Authenticated: The user has succesfully signed in to Azure and Firebase/Crisp.
 * LoginRequired: Login popup is required.
 * SignedInWithAnotherUser: There is another Firebase user logged in. Prompt to link user accounts.
 * UserAlreadyExists: The Azure user has en email address that is registered on another Firebase user which is not logged in.
 *                    Prompt user to sign in.
 * AzureFirebaseUserMismatch: We have successfully signed in with both the Azure and Firebase users, but the Firebase user is not matching
 *                            the Azure user or the Firebase user is not authenticated with the OIDC provider.
 */
export enum UnfiAuthState {
  Authenticated = 'authenticated',
  Loading = 'loading',
  LoginRequired = 'login_required',
  SignedInWithAnotherUser = 'signed_in_with_another_user',
  UserAlreadyExists = 'user_already_exists',
  Failed = 'failed',
  FirebaseAzureUserMismatch = 'firebase_azure_user_mismatch',
}

export type UnfiAuthError = {
  origin: string;
  code: string;
  message: string;
};

export type Nonce = {
  nonce: string;
  rawNonce: string;
};

const genString = (): string => {
  let result = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charsLength = chars.length;
  for (let i = 0; i < NONCE_LENGTH; i++) {
    result += chars.charAt(Math.floor(Math.random() * charsLength));
  }
  return result;
};

export const generateNonce = (): Nonce => {
  const rawNonce = genString();
  const hash = sha256(rawNonce);

  return {
    nonce: hash,
    rawNonce: rawNonce,
  };
};

export const toUnfiAuthError = (
  error: AzureAuthError | FirebaseAuthError,
): UnfiAuthError | undefined => {
  if (error instanceof AzureAuthError)
    return {
      origin: 'azure',
      code: error.errorCode,
      message: error.errorMessage,
    };
  else if (error instanceof FirebaseError)
    return {
      origin: 'firebase',
      code: error.code,
      message: error.message,
    };
};

export const providerInfo = (
  user: firebase.User,
  providerId: string,
): firebase.UserInfo | undefined | null =>
  user?.providerData?.find(userInfo => userInfo?.providerId === providerId);

export const isLinked = (
  providerId: string,
  msUser: AccountInfo,
  firebaseUser: firebase.User,
): boolean => {
  const info = providerInfo(firebaseUser, providerId);
  return (info && info.uid === msUser.idTokenClaims?.sub) || false;
};
